const styles = () => ({
  pageBox: {
    root: { backgroundColor: '#fff' },
    backgroundColor: '#fff',
    padding: 0,
    maxWidth: 1200,
  },
  paper: {
    padding: 20,
    textAlign: 'center',
  },
});

export default styles;
