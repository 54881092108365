import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const getAllPapeletasByUserId = _ced => {
  return axiosInstance.get(
    `papeletaPresidenteRegimenMunicipal/${_ced}`,
  );
};

const declinePapeleta = params => {
  const { codPapeleta, cedulaGestor, consecutivo } = params;

  const info = {
    codPapeleta,
    cedulaGestor,
    consecutivo,
  };

  return axiosInstance.post(
    `papeletaPresidenteRegimenMunicipal/anular`,
    info,
  );
};

export default {
  getPresidentesRegimenMunicipal: getAllPapeletasByUserId,
  actionDeclinePapeleta: declinePapeleta,
};
