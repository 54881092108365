/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MacroList from '../MacroList/MacroList';
import style from './styles';
import Copyright from '../Copyright/Copyright';

import adjudicacionPuestos from '../../Core/Api/adjudicacionPuestos';

function createData(_data) {
  return {
    candidato: _data.num_cedula,
    nombre: _data.des_nombre,
  };
}

const APPresRegimenMunicipal = props => {
  const { classes } = props;

  // eslint-disable-next-line no-unused-vars
  const [showResults, setShowResults] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const [isDataEmpty, setDataEmpty] = useState(false);

  const [filteredItems, setFilteredItems] = useState([
    ['', ''],
  ]);
  const [currentDate, setCurrentDate] = useState('');

  const heads = [
    {
      name: 'Cédula',
    },
    {
      name: 'Nombre',
    },
  ];

  const getTimeStamp = someDate => {
    const now = new Date(someDate);
    return `${now.getHours()}:${
      now.getMinutes() < 10
        ? `0${now.getMinutes()}`
        : now.getMinutes()
    }`;
  };

  // Date Options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // timeZone: 'UTC',
  };

  const formatDate = date => {
    const dateParts = date.split('/');
    const month = dateParts[1];
    const day = dateParts[0];
    const year = dateParts[2];

    return [day, month, year].join('/');
  };

  useEffect(() => {
    setIsLoading(true);
    async function fetchListAPI() {
      await adjudicacionPuestos
        .getReporteResultados004()
        .then(result => {
          setIsLoading(false);
          const info = Lodash.get(
            result,
            ['data', 'resultados'],
            [],
          );
          const currDate = Lodash.get(
            result,
            ['data', 'currentDate'],
            [],
          );

          const cDate = formatDate(
            new Date(currDate).toLocaleDateString(
              'es-ES',
              options,
            ),
          );
          const cHora = getTimeStamp(currDate);

          if (info instanceof Object && info.length > 0) {
            const rowItems = info.map(row => {
              return createData(row);
            });
            setFilteredItems(rowItems);
            setCurrentDate(
              `Fecha: ${cDate} | Hora: ${cHora}`,
            );
            setDataEmpty(false);
          } else {
            setFilteredItems([]);
            setDataEmpty(true);
          }
        })
        .catch(_error => {
          // eslint-disable-next-line no-console
          console.log(_error);
        });
    }

    fetchListAPI();
  }, []);

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    return actions;
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <h3 style={{ textAlign: 'center' }}>
        ADJUDICACIÓN DE PUESTOS
      </h3>
      <h4
        className={classes.subtitle}
        style={{ textAlign: 'center' }}
      >
        Presidencia de Régimen Municipal
      </h4>

      <h4
        style={{
          textAlign: 'center',
          color: '#008945',
          marginTop: 40,
          fontSize: 18,
        }}
      >
        {currentDate}
      </h4>

      {isLoading ? (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <CircularProgress
            style={{
              width: '28px',
              height: '24px',
            }}
          />
        </div>
      ) : (
        <MacroList
          dataEmpty={isDataEmpty}
          listName=""
          uiStylesClasses={classes}
          btnConfig={{
            name: '+ Registro',
            event: () =>
              (window.location.href = `/presidente/add`),
          }}
          actionConfig={setActions}
          tableHeads={heads}
          tableBody={filteredItems}
          displayCopyright={false}
          isCrudAvailable={false}
        />
      )}

      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  );
};

APPresRegimenMunicipal.propTypes = {};

export default withStyles(style)(APPresRegimenMunicipal);
