/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Lodash from 'lodash';
import { useJwt } from 'react-jwt';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { makeStyles } from '@material-ui/core/styles';
import Storage from '../../Storage/Storage';

import { getTotalPapeletas } from '../../Core/Api/gestorApi';

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },

  paper: {
    padding: 20,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },

  cardIcon: {
    background: '#84BF48',
  },
});

export default function Cards() {
  const classes = useStyles();
  const tokenStorage = Storage.instance;

  const { decodedToken } = useJwt(tokenStorage.getToken());

  const [totales, setTotales] = useState([]);

  useEffect(() => {
    async function fetchTotalesAPI() {
      if (decodedToken) {
        const allTotales = await getTotalPapeletas(
          decodedToken.num_cedula,
        );

        const dataTotales = Lodash.get(
          allTotales,
          ['data', 'response'],
          [],
        );
        setTotales(dataTotales);
      }
    }

    fetchTotalesAPI();
  }, [decodedToken]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            component="h2"
            variant="h6"
            color="primary"
            gutterBottom
          >
            Papeletas
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs>
                Delegados Provinciales
                <Typography component="p" variant="h4">
                  <span style={{ fontSize: 20 }}>
                    {totales.provinciales} registros
                  </span>
                </Typography>
                <a href="/delegados-provinciales">
                  <Typography
                    style={{
                      fontSize: 14,
                      color: '#008945',
                    }}
                  >
                    ver todas
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <Avatar className={classes.cardIcon}>
                  <ReceiptIcon />
                </Avatar>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs>
                Comité Ejecutivo Provincial
                <Typography component="p" variant="h4">
                  <span style={{ fontSize: 20 }}>
                    {totales.comite} registros
                  </span>
                </Typography>
                <a href="/comite-ejecutivo-provincial">
                  <Typography
                    style={{
                      fontSize: 14,
                      color: '#008945',
                    }}
                  >
                    ver todas
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <Avatar className={classes.cardIcon}>
                  <ReceiptIcon />
                </Avatar>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs>
                Rep. Régimen Municipal
                <Typography component="p" variant="h4">
                  <span style={{ fontSize: 20 }}>
                    {totales.regimen} registros
                  </span>
                </Typography>
                <a href="/representantes-regimen-municipal">
                  <Typography
                    style={{
                      fontSize: 14,
                      color: '#008945',
                    }}
                  >
                    ver todas
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <Avatar className={classes.cardIcon}>
                  <ReceiptIcon />
                </Avatar>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs>
                Presidente. Régimen Municipal
                <Typography component="p" variant="h4">
                  <span style={{ fontSize: 20 }}>
                    {totales.presidentes} registros
                  </span>
                </Typography>
                <a href="/presidente-regimen-municipal">
                  <Typography
                    style={{
                      fontSize: 14,
                      color: '#008945',
                    }}
                  >
                    ver todas
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <Avatar className={classes.cardIcon}>
                  <ReceiptIcon />
                </Avatar>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
