/* eslint-disable max-len */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';
import {
  Menu,
  MenuItem,
  makeStyles,
  Theme,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    backgroundColor: ({
      isSubMenuOpen,
    }: {
      isSubMenuOpen: boolean;
    }) =>
      isSubMenuOpen
        ? theme.palette.action.hover
        : 'transparent',
    minWidth: '12rem',
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    paddingRight: 6,
  },
  expandIcon: {
    fontSize: 12,
  },
}));

type Item = {
  id: string;
  name: string;
  children?: Item[];
};

interface NestedMenuItemProps {
  id: string;
  name: string;
  childrenItems?: Item[];
  onClick: (id: string) => void;
}

const NestedMenuItem = React.forwardRef<
  any,
  NestedMenuItemProps
>(
  (
    {
      // eslint-disable-next-line react/prop-types
      id: parentId,
      // eslint-disable-next-line react/prop-types
      name: parentName,
      // eslint-disable-next-line react/prop-types
      childrenItems: parentChildrenItems = [],
      // eslint-disable-next-line react/prop-types
      onClick,
    },
    ref,
  ) => {
    const [
      anchorEl,
      setAnchorEl,
    ] = React.useState<null | HTMLElement>(null);
    const isSubMenuOpen = Boolean(Boolean(anchorEl));
    const classes = useStyles({ isSubMenuOpen });
    const hasChildrenItems =
      parentChildrenItems?.length || false;
    const isLeafNode = !hasChildrenItems;

    const handleMouseEnter = (
      event: React.MouseEvent<any>,
    ) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<any>) => {
      event.stopPropagation();
      if (isLeafNode) {
        onClick(parentId);
      }
    };

    return (
      <MenuItem
        ref={ref}
        disableRipple
        className={classes.menuItem}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleClose}
      >
        <div className={classes.contentContainer}>
          <span className={classes.name}>{parentName}</span>
          {hasChildrenItems && (
            <ArrowForwardIosIcon
              className={classes.expandIcon}
            />
          )}
        </div>
        {hasChildrenItems && (
          <>
            <Menu
              // "pointerEvents: none" to prevent invisible Popover wrapper div to capture mouse events
              style={{ pointerEvents: 'none' }}
              anchorEl={anchorEl}
              open={isSubMenuOpen}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                elevation: 4,
              }}
            >
              {/* reset pointer event here so that the menu items could receive mouse events */}
              <div style={{ pointerEvents: 'auto' }}>
                {parentChildrenItems.map(item => {
                  const { id, name, children } = item;
                  return (
                    <NestedMenuItem
                      key={id}
                      id={id}
                      name={name}
                      childrenItems={children}
                      onClick={onClick}
                    />
                  );
                })}
              </div>
            </Menu>
          </>
        )}
      </MenuItem>
    );
  },
);

export default NestedMenuItem;
