import axios from 'axios';
import Lodash from 'lodash';
import { useQuery } from 'react-query';
import Consts from '../Consts/Consts';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const addNew = params => {
  const { presidenteEnca } = params;

  return axios.post(
    '/papeletaPresidenteRegimenMunicipal',
    presidenteEnca,
  );
};

export const getNumPapeletasNacionales = (
  idProvince,
  idMovimiento,
) => {
  const url = [
    `/papeletaPresidenteRegimenMunicipal/numPapeletas/${idProvince}/${idMovimiento}`,
  ];

  return axios.get(url.join('&'));
};

export const searchPapeletas = () => {
  const url = [
    `/papeletaPresidenteRegimenMunicipal/doSearch`,
  ];
  return axios.get(url.join('&'));
};

export const getNumPapeletaStatus = params => {
  const { papeleta, provincia, movimiento } = params;
  const url = [
    `/papeletaPresidenteRegimenMunicipal/numPapeletaStatus/${papeleta}/${provincia}/${movimiento}`,
  ];
  return axios.get(url.join('&'));
};

export const blockNumPapeleta = params => {
  const { numPapeleta, codProvincia, codMovimiento } =
    params;

  const papeletaData = {
    numPapeleta,
    codProvincia,
    codMovimiento,
  };

  return axios.post(
    '/papeletaPresidenteRegimenMunicipal/blockNumPapeleta',
    papeletaData,
  );
};

export const updatePapeleta = params => {
  const { codPapeleta, presidenteEnca } = params;

  const papeletaData = {
    codPapeleta,
    presidenteEnca,
  };

  return axios.post(
    '/papeletaPresidenteRegimenMunicipal/update',
    papeletaData,
  );
};

const getCandidatosPapeleta = async codPapeleta => {
  const url = [
    `/papeletaPresidenteRegimenMunicipal/getCandidatosPapeleta/${codPapeleta}`,
  ];

  const { data } = await axios.get(url.join('&'));

  return data;
};

const searchByCedula = async cedula => {
  const url = [`/padron/searchbycedula/${cedula}`];
  const { data } = await axios.get(url.join('&'));
  return data;
};

const preparedList = list => {
  const unlockedList = list.map(e => {
    Lodash.set(e, 'enabled', false);
    return e;
  });
  return unlockedList;
};

const getAllDataPapeleta = async (data, codPapeleta) => {
  const initValues = Consts.presidenteSectorInitValues;

  // Se preparan las listas para que los campos esten desbloquedos
  const iListComite = preparedList(
    Consts.initlistPresiSector,
  );

  // Se obtiene el numero de delegados
  const numCandidatos = 1;

  // Se obtienen todos los candidatos registrados en la papeleta
  const candidatos = await getCandidatosPapeleta(
    codPapeleta,
  );

  /* ====== Lista de Comité ====== */

  const listComite = await Promise.all(
    candidatos.map(async (d, index) => {
      const miembro = await searchByCedula(d.NUM_CEDULA);

      // Datos de la persona
      const person = Lodash.get(miembro, ['response', 0]);

      // Nombre de la persona
      const personName = `${person.des_nombre} ${person.des_apell1} ${person.des_apell2}`;
      const personCedula = person.num_cedula;
      const personEdad = person.num_edad;
      let sexVal = 'M';
      if (person.sexo !== 1) {
        sexVal = 'F';
      }

      // Nombre del campo
      const field = Lodash.get(iListComite, [index]);

      Lodash.set(initValues, field.fieldName, personCedula);

      return {
        id: index + 1,
        lugar: field.lugar,
        // numPuesto: d.NUM_PUESTO,
        fieldName: field.fieldName,
        cedula: personCedula,
        nombre: personName,
        sexo: sexVal,
        edad: personEdad,
        enabled: true,
        filled: true,
      };
    }),
  );

  // Merging List Comite
  const allComite = Lodash.merge(iListComite, listComite);
  const firstComite = allComite[0].sexo;
  let sexOrderComite = Consts.presiSectorMOrder;
  if (firstComite !== 'M') {
    sexOrderComite = Consts.presiSectorFOrder;
  }

  // Se establece el sexo para el resto de la lista
  const finalListComite = Lodash.map(
    allComite,
    (d, index) => {
      const newData = {
        id: d.id,
        lugar: d.lugar,
        numPuesto: d.numPuesto,
        fieldName: d.fieldName,
        cedula: d.cedula,
        nombre: d.nombre,
        sexo: sexOrderComite[index].sexo,
        edad: d.edad,
        enabled: d.cedula !== '',
        filled: d.filled,
      };

      return newData;
    },
  );

  return {
    papeletaData: data,
    numCandidatos,
    candidatos: {
      listComite: finalListComite,
    },
    initValues,
  };
};

export const getPapeletaInfo = async params => {
  try {
    const { cedGestor, codPapeleta } = params;

    const url = [
      `/papeletaPresidenteRegimenMunicipal/getByCodPapeleta/${cedGestor}/${codPapeleta}`,
    ];

    const { data } = await axios.get(url.join('&'));

    const papeletaData = getAllDataPapeleta(
      data.response[0],
      codPapeleta,
    );

    return papeletaData;
  } catch (e) {
    return { papeletaData: {} };
  }
};

export const getDataComprobante = params => {
  const { cedGestor, codPapeleta } = params;
  const url = [
    `/papeletaPresidenteRegimenMunicipal/dataComprobante/${cedGestor}/${codPapeleta}`,
  ];

  return axios.get(url.join('&'));
};

export const usePapeletaInfo = (cedGestor, codPapeleta) => {
  return useQuery(
    ['papeletaData', cedGestor, codPapeleta],
    () => getPapeletaInfo(cedGestor, codPapeleta),
  );
};
