/* eslint-disable camelcase */
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

/* eslint-disable import/prefer-default-export */
export const validateCandidato = cedula => {
  const url = [`/papeleta/validateCandidatoEP/${cedula}`];

  return axios.get(url.join('&'));
};

export const validateFiscal = cedula => {
  const url = [`/papeleta/validateFiscalEP/${cedula}`];

  return axios.get(url.join('&'));
};

export const validatePapeletaDelegadosProvinciales = (
  cedula,
  currentCodPapeleta,
) => {
  const url = [
    `/papeleta/validatePapeletaDelegadosProvinciales/${cedula}/${currentCodPapeleta}`,
  ];

  return axios.get(url.join('&'));
};
