/* eslint-disable max-len */
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const getReporteResultados001 = codProvincia => {
  return axiosInstance.get(
    `adjudicacionPuestos/getReporteResultadosEP001/${codProvincia}`,
  );
};

const getReporteResultados002 = codProvincia => {
  return axiosInstance.get(
    `adjudicacionPuestos/getReporteResultadosEP002/${codProvincia}`,
  );
};

const getReporteResultados003 = codProvincia => {
  return axiosInstance.get(
    `adjudicacionPuestos/getReporteResultadosEP003/${codProvincia}`,
  );
};

const getReporteResultados004 = () => {
  return axiosInstance.get(
    `adjudicacionPuestos/getReporteResultadosEP004`,
  );
};

export default {
  getReporteResultados001,
  getReporteResultados002,
  getReporteResultados003,
  getReporteResultados004,
};
