/* eslint-disable no-unused-vars */
import axios from 'axios';
import Lodash from 'lodash';
import { useQuery } from 'react-query';
import Consts from '../Consts/Consts';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const addNew = params => {
  const { delegadosProvincialEnca, tFrmsCandidatos } =
    params;

  const papeletaData = {
    delegadosProvincialEnca,
    tFrmsCandidatos,
  };

  return axios.post(
    '/papeletaRepresentantesRegimenMunicipal',
    papeletaData,
  );
};

export const getNumPapeletasDelegadoProvincial =
  idProvince => {
    const url = [
      `/papeletaRepresentantesRegimenMunicipal/numPapeletas/${idProvince}`,
    ];

    return axios.get(url.join('&'));
  };

export const searchPapeletas = (codProvincia, numero) => {
  const url = [
    `/papeletaRepresentantesRegimenMunicipal/doSearch/${codProvincia}/${numero}`,
  ];

  return axios.get(url.join('&'));
};

export const getDetallePapeleta = codPapeleta => {
  const url = [
    `/papeletaRepresentantesRegimenMunicipal/getDetallePapeleta/${codPapeleta}`,
  ];

  return axios.get(url.join('&'));
};

export const getNumPapeletaStatus = params => {
  const { papeleta, provincia } = params;
  const url = [
    `/papeletaRepresentantesRegimenMunicipal/numPapeletaStatus/${papeleta}/${provincia}`,
  ];
  return axios.get(url.join('&'));
};

export const blockNumPapeleta = params => {
  const { numPapeleta, codProvincia } = params;

  const papeletaData = {
    numPapeleta,
    codProvincia,
  };

  return axios.post(
    '/papeletaRepresentantesRegimenMunicipal/blockNumPapeleta',
    papeletaData,
  );
};

export const updatePapeleta = params => {
  const {
    codPapeleta,
    delegadosProvincialEnca,
    tFrmsCandidatos,
  } = params;

  const papeletaData = {
    codPapeleta,
    delegadosProvincialEnca,
    tFrmsCandidatos,
  };

  return axios.post(
    '/papeletaRepresentantesRegimenMunicipal/update',
    papeletaData,
  );
};

const getCandidatosPapeleta = async codPapeleta => {
  const url = [
    `/papeleta/getCandidatosPapeletaRRM/${codPapeleta}`,
  ];

  const { data } = await axios.get(url.join('&'));

  return data;
};

const getCantidadDelegados = async (
  idProvince,
  idCanton,
) => {
  const url = [
    `/cantons/delegados/${idProvince}/${idCanton}`,
  ];

  const { data } = await axios.get(url.join('&'));

  return data[0].numDelegados;
};

const searchByCedula = async cedula => {
  const url = [`/padron/searchbycedula/${cedula}`];
  const { data } = await axios.get(url.join('&'));
  return data;
};

const preparedList = list => {
  const unlockedList = list.map(e => {
    Lodash.set(e, 'enabled', false);
    return e;
  });
  return unlockedList;
};

const getAllDataPapeleta = async (data, codPapeleta) => {
  const initValues = Consts.delegadosProvincialesInitValues;
  Lodash.set(initValues, 'provincia', data.cod_prov);
  // Estado: REGISTRADA
  if (data.ind_estado === 2) {
    Lodash.set(initValues, 'papeleta', 999);
    // Estado: INSCRITA
  } else {
    const numStatus = await getNumPapeletaStatus({
      papeleta: data.numero,
      provincia: data.cod_prov,
    });

    const numeroStatus = Lodash.get(numStatus, ['data']);

    if (numeroStatus === 1) {
      Lodash.set(initValues, 'papeleta', data.numero);
    } else {
      Lodash.set(initValues, 'papeleta', 999);
    }
  }

  const allPapeletas =
    await getNumPapeletasDelegadoProvincial(data.cod_prov);

  const papeletasList = Lodash.get(allPapeletas, ['data']);
  const plist = papeletasList.map(({ papeleta }) => {
    return {
      itemLabel: papeleta,
      value: papeleta,
    };
  });
  plist.unshift({
    itemLabel: '- NUMERO DE PAPELETA -',
    value: 999,
  });

  // Se preparan las listas para que los campos esten desbloquedos
  const iListDelegados = preparedList(
    Consts.initListDelegadosProvinciales,
  );

  // Se obtiene el numero de delegados
  const numDelegados = 4;

  // Se obtienen todos los candidatos registrados en la papeleta
  const candidatos = await getCandidatosPapeleta(
    codPapeleta,
  );

  /* ====== Lista de Delegados ====== */
  const delegados = candidatos.filter(candidato => {
    return candidato.NUM_PUESTO < 5;
  });

  const listDelegados = await Promise.all(
    delegados.map(async (d, index) => {
      const delegado = await searchByCedula(d.NUM_CEDULA);

      // Datos de la persona
      const person = Lodash.get(delegado, ['response', 0]);

      // Nombre de la persona
      const personName = `${person.des_nombre} ${person.des_apell1} ${person.des_apell2}`;
      const personCedula = person.num_cedula;
      const personEdad = person.num_edad;

      let sexVal = 'M';
      if (person.sexo !== 1) {
        sexVal = 'F';
      }

      Lodash.set(
        initValues,
        `delegado${d.NUM_PUESTO}`,
        personCedula,
      );

      return {
        id: d.NUM_PUESTO,
        lugar: d.NUM_PUESTO,
        numPuesto: d.NUM_PUESTO,
        fieldName: `delegado${d.NUM_PUESTO}`,
        cedula: personCedula,
        nombre: personName,
        sexo: sexVal,
        edad: personEdad,
        enabled: true,
        filled: true,
      };
    }),
  );

  const aDelegados = iListDelegados.map(del => {
    const f = Lodash.find(listDelegados, {
      fieldName: del.fieldName,
    });

    if (f === undefined) {
      return del;
    }
    return f;
  });

  // Merging List Delegados
  const allDelegados = Lodash.merge(
    iListDelegados,
    aDelegados,
  );

  const firstDelegado = allDelegados[0].sexo;
  let sexOrder = Consts.delegadosMOrderPro;
  if (firstDelegado !== 'M') {
    sexOrder = Consts.delegadosFOrderPro;
  }

  // Se establece el sexo para el resto de la lista
  const finalListDelegados = Lodash.map(
    allDelegados,
    (d, index) => {
      const newData = {
        id: d.id,
        lugar: d.lugar,
        numPuesto: d.numPuesto,
        fieldName: d.fieldName,
        cedula: d.cedula,
        nombre: d.nombre,
        sexo: sexOrder[index].sexo,
        edad: d.edad,
        enabled: d.cedula !== '',
        filled: d.filled,
      };

      return newData;
    },
  );

  return {
    papeletaData: data,
    numDelegados,
    papeletas: plist,
    candidatos: {
      listDelegados: finalListDelegados,
    },
    initValues,
  };
};

export const getPapeletaInfo = async params => {
  try {
    const { cedGestor, codPapeleta } = params;

    const url = [
      `/papeletaRepresentantesRegimenMunicipal/getByCodPapeleta/${cedGestor}/${codPapeleta}`,
    ];

    const { data } = await axios.get(url.join('&'));
    const papeletaData = getAllDataPapeleta(
      data.response[0],
      codPapeleta,
    );

    return papeletaData;
  } catch (e) {
    return { papeletaData: {} };
  }
};

export const usePapeletaInfo = (cedGestor, codPapeleta) => {
  return useQuery(
    ['papeletaData', cedGestor, codPapeleta],
    () => getPapeletaInfo(cedGestor, codPapeleta),
  );
};
