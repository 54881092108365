import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const getPapeletaDistritalByCod = (_ced, _codDistrital) => {
  return axiosInstance.get(
    `papeletaDistrital/getByCodPapeleta/${_ced}/${_codDistrital}`,
  );
};

const getAllRepresentantesRegimenMunicipalByUserId =
  _ced => {
    return axiosInstance.get(
      `papeletaRepresentantesRegimenMunicipal/${_ced}`,
    );
  };

const declinePapeleta = params => {
  const { codPapeleta, cedulaGestor, consecutivo } = params;

  const info = {
    codPapeleta,
    cedulaGestor,
    consecutivo,
  };

  return axiosInstance.post(
    `papeletaRepresentantesRegimenMunicipal/anular`,
    info,
  );
};

export default {
  getSpecificDistrital: getPapeletaDistritalByCod,
  getRepresentantesRegimenMunicipal:
    getAllRepresentantesRegimenMunicipalByUserId,
  actionDeclinePapeleta: declinePapeleta,
};
