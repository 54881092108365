import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import shortId from 'shortid';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import style from './styles';

const PlnCheckbox = withStyles(style)(props => {
  const {
    classes,
    id,
    label,
    labelChecked,
    position,
    onChange,
    field,
    checked,
    required: isRequired,
  } = props;
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    onChange(isChecked);
  }, [onChange, isChecked]);

  return (
    <div className={classes.checkboxContainer}>
      <input
        type="checkbox"
        style={{ display: 'none' }}
        id={id}
        value={isChecked}
        onChange={e => {
          setIsChecked(!isChecked);
          field.onChange(e);
        }}
        name={field.name}
        onBlur={field.onBlur}
      />
      <label
        htmlFor={id}
        className={clsx(isRequired && classes.required)}
      >
        {labelChecked ? (
          <span>{isChecked ? labelChecked : label}</span>
        ) : (
          <span>{label}</span>
        )}
        <div
          className={clsx(
            classes.checkbox,
            classes[position],
          )}
        >
          {isChecked && (
            <CheckIcon className={classes.checkboxIcon} />
          )}
        </div>
      </label>
    </div>
  );
});

PlnCheckbox.defaultProps = {
  id: shortId.generate(),
  label: '',
  position: 'left',
  onChange: () => {},
  checked: false,
};

PlnCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  labelChecked: PropTypes.string,
  position: PropTypes.string,
  onChange: PropTypes.func,
};

export default PlnCheckbox;
