import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import visa from 'payment-icons/min/single/visa.svg';
import mastercard from 'payment-icons/min/single/mastercard.svg';
import discover from 'payment-icons/min/single/discover.svg';
import amex from 'payment-icons/min/single/amex.svg';
import monoDefault from 'payment-icons/min/mono/default.svg';
import ccType from 'credit-card-type';
import style from './styles';

const PlnInputCard = withStyles(style)(props => {
  const {
    classes,
    label,
    field,
    form: { touched, errors },
    required: isRequired,
    placeholder,
    onUpdate,
  } = props;

  const { t } = useTranslation();

  const [creditCardType, setCreditCardType] = useState('');
  const [mask, setMask] = useState('9999 9999 9999 9999');

  useEffect(() => {
    switch (creditCardType) {
      case 'american-express':
        setMask('9999 9999999 99999');
        onUpdate('american-express');
        break;
      case 'visa':
        setMask('9999 9999 9999 9999');
        onUpdate('visa');
        break;
      case 'mastercard':
        setMask('9999 9999 9999 9999');
        onUpdate('mastercard');
        break;
      case 'discover':
        setMask('9999 9999 9999 9999');
        onUpdate('discover');
        break;
      default:
        setCreditCardType('');
        setMask('9999 9999 9999 9999');
        onUpdate('');
        break;
    }

    if (field.value.length >= 2) {
      const types = ccType(field.value);

      if (types.length) {
        setCreditCardType(types[0].type);
      } else {
        setCreditCardType('');
      }
    } else {
      setCreditCardType('');
    }
  }, [creditCardType, field.value, onUpdate]);

  const renderCCImage = cctype => {
    switch (cctype) {
      case 'american-express':
        return (
          <img
            alt="amex"
            src={amex}
            className={classes.ccImg}
          />
        );
      case 'visa':
        return (
          <img
            alt="visa"
            src={visa}
            className={classes.ccImg}
          />
        );
      case 'mastercard':
        return (
          <img
            alt="mastercard"
            src={mastercard}
            className={classes.ccImg}
          />
        );
      case 'discover':
        return (
          <img
            alt="discover"
            src={discover}
            className={classes.ccImg}
          />
        );
      default:
        return (
          <img
            alt="credit card"
            src={monoDefault}
            className={classes.ccImg}
          />
        );
    }
  };

  return (
    <div>
      <label
        htmlFor={field.name}
        className={clsx(
          classes.inputLabel,
          isRequired && classes.required,
        )}
      >
        {t(label)}
      </label>
      <div className={classes.ccContainer}>
        <InputMask
          className={clsx(
            classes.input,
            classes.ccField,
            touched[field.name] &&
              errors[field.name] &&
              classes.inputError,
          )}
          placeholder={placeholder}
          maskChar={null}
          mask={mask}
          type="tel"
          id={field.name}
          {...field}
        />
        <div className={classes.cc}>
          {renderCCImage(creditCardType)}
        </div>
      </div>
    </div>
  );
});

PlnInputCard.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default PlnInputCard;
