/* eslint-disable max-len */
import RegistroGestores from '../Containers/RegistroGestores/RegistroGestores';
import Login from '../Containers/Login/Login';
import ForgotPassword from '../Containers/Login/ForgotPassword';
import MainPage from '../Containers/MainPage/MainPage';
import Dashboard from '../Containers/Dashboard/Dashboard';

// Registro Papeletas
import DelegadosProvinciales from '../Containers/DelegadosProvinciales/DelegadosProvinciales';
import AddDelegadosProvinciales from '../Containers/DelegadosProvinciales/AddDelegadosProvinciales';
import EditDelegadosProvinciales from '../Containers/DelegadosProvinciales/EditDelegadosProvinciales';

import ComiteEjecutivoProvincial from '../Containers/ComiteEjecutivoProvincial/ComiteEjecutivoProvincial';
import AddComiteEjecutivoProvincial from '../Containers/ComiteEjecutivoProvincial/AddComiteEjecutivoProvincial';
import EditComiteEjecutivoProvincial from '../Containers/ComiteEjecutivoProvincial/EditComiteEjecutivoProvincial';

import RepresentantesRegimenMunicipal from '../Containers/RepresentantesRegimenMunicipal/RepresentantesRegimenMunicipal';
import AddRepresentantesRegimenMunicipal from '../Containers/RepresentantesRegimenMunicipal/AddRepresentantesRegimenMunicipal';
import EditRepresentantesRegimenMunicipal from '../Containers/RepresentantesRegimenMunicipal/EditRepresentantesRegimenMunicipal';

import PresidenteRegimenMunicipal from '../Containers/PresidenteRegimenMunicipal/PresidenteRegimenMunicipal';
import AddPresidenteRegimenMunicipal from '../Containers/PresidenteRegimenMunicipal/AddPresidenteRegimenMunicipal';
import EditPresidenteRegimenMunicipal from '../Containers/PresidenteRegimenMunicipal/EditPresidenteRegimenMunicipal';

// Registro Depositos
import RegistroDepositos from '../Containers/RegistroDepositos/RegistroDepositos';
import AddDeposito from '../Containers/RegistroDepositos/AddDeposito';
import EditDeposito from '../Containers/RegistroDepositos/EditDeposito';

// Consulta Debitos
import ConsultaDebitos from '../Containers/ConsultaDebitos/ConsultaDebitos';

// Formularios
import FRMPapeletaDelegadosProvinciales from '../Containers/Formulario/FRMPapeletaDelegadosProvinciales';
import FRMComiteEjecutivoProvincial from '../Containers/Formulario/FRMComiteEjecutivoProvincial';
import FRMRepRegimenMunicipal from '../Containers/Formulario/FRMRepRegimenMunicipal';
import FRMPresidenteRegimenMunicipal from '../Containers/Formulario/FRMPresidenteRegimenMunicipal';
import FRMHojaInformacion from '../Containers/Formulario/FRMHojaInformacion';

// Consultas Públicas
import CPPapeletasDelegadosProvinciales from '../Containers/ConsultasPublicas/CPPapeletasDelegadosProvinciales';
import CPPapeletasComiteEjecutivoProvincial from '../Containers/ConsultasPublicas/CPPapeletasComiteEjecutivoProvincial';
import CPPapeletasRepresentantesRegimenMunicipal from '../Containers/ConsultasPublicas/CPPapeletasRepresentantesRegimenMunicipal';
import CPPapeletasPresidenteRegimenMunicipal from '../Containers/ConsultasPublicas/CPPapeletasPresidenteRegimenMunicipal';

// Adjudicación de Puestos
import APDelegadosProvinciales from '../Containers/AdjudicacionPuestos/APDelegadosProvinciales';
import APComiteEjecutivoProvincial from '../Containers/AdjudicacionPuestos/APComiteEjecutivoProvincial';
import APRepRegimenMunicipal from '../Containers/AdjudicacionPuestos/APRepRegimenMunicipal';
import APPresRegimenMunicipal from '../Containers/AdjudicacionPuestos/APPresRegimenMunicipal';

const routesList = [
  {
    auth: false,
    component: MainPage,
    exact: true,
    name: 'mainPage',
    path: '/',
  },
  {
    auth: false,
    component: Login,
    exact: true,
    name: 'login',
    path: '/login',
  },
  {
    auth: false,
    component: ForgotPassword,
    exact: true,
    name: 'forgotPwd',
    path: '/forgot-password',
  },
  {
    auth: true,
    component: Dashboard,
    exact: true,
    name: 'dashboard',
    path: '/dashboard',
  },
  {
    auth: true,
    component: DelegadosProvinciales,
    exact: true,
    name: 'delegados-provinciales',
    path: '/delegados-provinciales',
  },
  {
    auth: true,
    component: AddDelegadosProvinciales,
    exact: true,
    name: 'addDelegadosProvinciales',
    path: '/delegados-provinciales/add',
  },
  {
    auth: true,
    component: EditDelegadosProvinciales,
    exact: true,
    name: 'editDelgadosProvinciales',
    path: '/delegados-provinciales/edit/:id',
  },
  {
    auth: true,
    component: ComiteEjecutivoProvincial,
    exact: true,
    name: 'comiteEjecutivoProvincial',
    path: '/comite-ejecutivo-provincial',
  },
  {
    auth: true,
    component: AddComiteEjecutivoProvincial,
    exact: true,
    name: 'addComiteEjecutivoProvincial',
    path: '/comite-ejecutivo-provincial/add',
  },
  {
    auth: true,
    component: EditComiteEjecutivoProvincial,
    exact: true,
    name: 'editComiteEjecutivoProvincial',
    path: '/comite-ejecutivo-provincial/edit/:id',
  },
  {
    auth: true,
    component: RepresentantesRegimenMunicipal,
    exact: true,
    name: 'representantes-regimen-municipal',
    path: '/representantes-regimen-municipal',
  },
  {
    auth: true,
    component: AddRepresentantesRegimenMunicipal,
    exact: true,
    name: 'addRepresentantesRegimenMunicipal',
    path: '/representantes-regimen-municipal/add',
  },
  {
    auth: true,
    component: EditRepresentantesRegimenMunicipal,
    exact: true,
    name: 'editRepresentantesRegimenMunicipal',
    path: '/representantes-regimen-municipal/edit/:id',
  },
  {
    auth: true,
    component: PresidenteRegimenMunicipal,
    exact: true,
    name: 'presidente-regimen-municipal',
    path: '/presidente-regimen-municipal',
  },
  {
    auth: true,
    component: AddPresidenteRegimenMunicipal,
    exact: true,
    name: 'addPresidenteRegimenMunicipal',
    path: '/presidente-regimen-municipal/add',
  },
  {
    auth: true,
    component: EditPresidenteRegimenMunicipal,
    exact: true,
    name: 'editPresidenteRegimenMunicipal',
    path: '/presidente-regimen-municipal/edit/:id',
  },
  {
    auth: false,
    component: RegistroGestores,
    exact: true,
    name: 'registro-gestores',
    path: '/registro-gestores',
  },
  {
    auth: true,
    component: RegistroDepositos,
    exact: true,
    name: 'registroDepositos',
    path: '/registro-depositos',
  },
  {
    auth: true,
    component: AddDeposito,
    exact: true,
    name: 'addDeposito',
    path: '/registro-depositos/add',
  },
  {
    auth: true,
    component: EditDeposito,
    exact: true,
    name: 'editDeposito',
    path: '/registro-depositos/edit/:id',
  },
  {
    auth: true,
    component: ConsultaDebitos,
    exact: true,
    name: 'consultaDebitos',
    path: '/consulta-debitos',
  },
  {
    auth: false,
    component: FRMPapeletaDelegadosProvinciales,
    exact: true,
    name: 'frmPapeletaDelegadosProvinciales',
    path: '/formulario/papeletas-delegados-provinciales',
  },
  {
    auth: false,
    component: FRMComiteEjecutivoProvincial,
    exact: true,
    name: 'frmomiteEjecutivoProvincial',
    path: '/formulario/comite-ejecutivo-provincial',
  },
  {
    auth: false,
    component: FRMRepRegimenMunicipal,
    exact: true,
    name: 'frmRepRegimenMunicipal',
    path: '/formulario/representantes-regimen-municipal',
  },
  {
    auth: false,
    component: FRMPresidenteRegimenMunicipal,
    exact: true,
    name: 'frmPresidenteRegimenMunicipal',
    path: '/formulario/presidente-regimen-municipal',
  },
  {
    auth: false,
    component: FRMHojaInformacion,
    exact: true,
    name: 'FRMHojaInformacion',
    path: '/formulario/hoja-informacion',
  },
  {
    auth: false,
    component: CPPapeletasDelegadosProvinciales,
    exact: true,
    name: 'cpPapeletasDelegadosProvinciales',
    path: '/consulta-publica/papeletas-delegados-provinciales',
  },
  {
    auth: false,
    component: CPPapeletasComiteEjecutivoProvincial,
    exact: true,
    name: 'cpPapeletasComiteEjecutivoProvincial',
    path: '/consulta-publica/comite-ejecutivo-provincial',
  },
  {
    auth: false,
    component: CPPapeletasRepresentantesRegimenMunicipal,
    exact: true,
    name: 'cpPapeletasRepresentantesRegimenMunicipal',
    path: '/consulta-publica/representantes-regimen-municipal',
  },
  {
    auth: false,
    component: CPPapeletasPresidenteRegimenMunicipal,
    exact: true,
    name: 'cpPapeletasPresidenteRegimenMunicipal',
    path: '/consulta-publica/presidente-regimen-municipal',
  },
  {
    auth: false,
    component: APDelegadosProvinciales,
    exact: true,
    name: 'apDelegadosProvinciales',
    path: '/adjudicacion-puestos/delegados-provinciales',
  },
  {
    auth: false,
    component: APComiteEjecutivoProvincial,
    exact: true,
    name: 'apComiteEjecutivoProvincial',
    path: '/adjudicacion-puestos/comite-ejecutivo-provincial',
  },
  {
    auth: false,
    component: APRepRegimenMunicipal,
    exact: true,
    name: 'apRepRegimenMunicipal',
    path: '/adjudicacion-puestos/representantes-regimen-municipal',
  },
  {
    auth: false,
    component: APPresRegimenMunicipal,
    exact: true,
    name: 'apPresRegimenMunicipal',
    path: '/adjudicacion-puestos/presidente-regimen-municipal',
  },
];

export default routesList;
