import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import shortId from 'shortid';
import style from './styles';

const PlnDropdown = withStyles(style)(props => {
  const {
    classes,
    data,
    id,
    label,
    field,
    onChange,
    form: { touched, errors },
    required: isRequired,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={classes.dropdownContainer}>
      <label
        htmlFor={field.name}
        className={clsx(
          classes.inputLabel,
          isRequired && classes.required,
        )}
      >
        {t(label)}
      </label>

      <FormControl
        variant="outlined"
        className={classes.dropdownControl}
      >
        <Select
          className={clsx(
            classes.dropdown,
            touched[field.name] &&
              errors[field.name] &&
              classes.dropdownError,
          )}
          IconComponent={() => (
            <KeyboardArrowDownIcon
              className={classes.dropdownArrow}
            />
          )}
          value={field.value ? field.value : ''}
          id={id}
          onChange={e => {
            e.target.name = field.name;
            onChange(e.target.value);
            field.onChange(e);
          }}
        >
          {data.reduce((list, itm) => {
            const { value, itemLabel } = itm;
            list.push(
              <MenuItem
                key={shortId.generate()}
                value={value}
              >
                {itemLabel}
              </MenuItem>,
            );
            return list;
          }, [])}
        </Select>
      </FormControl>
    </div>
  );
});

PlnDropdown.defaultProps = {
  data: [],
  id: shortId.generate(),
  label: '',
  onChange: () => {},
};

PlnDropdown.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default PlnDropdown;
