/* eslint-disable max-len */
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { getLogoPLN } from './Logo';
import { splitInGroupOf } from './Utils';

const getTimeStamp = () => {
  const now = new Date();
  return `${
    now.getMonth() + 1
  }/${now.getDate()}/${now.getFullYear()} ${now.getHours()}:${
    now.getMinutes() < 10
      ? `0${now.getMinutes()}`
      : now.getMinutes()
  }:${
    now.getSeconds() < 10
      ? `0${now.getSeconds()}`
      : now.getSeconds()
  }`;
};

// eslint-disable-next-line import/prefer-default-export
export const generateComprobante = (
  titleComprobante,
  generalInfo,
  candidatosInfo,
  gestorInfo,
  montosInfo,
) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('p', 'px', 'letter');

  const imgData = getLogoPLN();
  doc.addImage(imgData, 'JPEG', 30, 50);

  // ENCABEZADO DEL DOCUMENTO
  doc.setFont('times', 'bold');
  doc.text(200, 45, 'PARTIDO LIBERACIÓN NACIONAL');
  doc.setFontSize(12);
  doc.text(200, 60, 'Tribunal de Elecciones Internas');
  doc.setFont('times', 'normal');
  doc.text(200, 75, `${titleComprobante} Periodo 2021`);
  doc.setFont('times', 'normal');
  doc.text(200, 90, 'Comprobante de Inscripción');

  const columns = ['PROVINCIA', 'N° PAPELETA'];

  const datos1 = [
    [generalInfo.provincia, generalInfo.papeleta],
  ];

  const typeTable1 = 'grid';
  const showTheHeader = true;

  doc.autoTable({
    head: [columns],
    body: datos1,
    theme: typeTable1,
    startY: 110,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      2: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      3: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
        fontSize: 30,
      },
    },
    headStyles: {
      fillColor: [238, 238, 238],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: showTheHeader,
    margin: { left: 20 },
  });

  const columns2 = ['Fecha', 'Consecutivo'];
  const datos2 = [
    [
      `FECHA INSCRIPCIÓN: ${generalInfo.fecha}`,
      `CONSECUTIVO: ${generalInfo.consecutivo}`,
    ],
  ];

  doc.autoTable({
    head: [columns2],
    body: datos2,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 0,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [204, 204, 204],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const block1 = ['Title'];
  const titleBlock = [
    ['CANDIDATOS(AS) DELEGADOS(AS) PROVINCIALES'],
  ];

  doc.autoTable({
    head: [block1],
    body: titleBlock,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [221, 221, 221],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const headerblock1 = ['LUGAR', 'CEDULA', 'NOMBRE'];

  doc.autoTable({
    head: [headerblock1],
    body: candidatosInfo.delegados,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      2: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [238, 238, 238],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: true,
    margin: { left: 20 },
  });

  const block4 = ['Gestor'];
  const titleBlock4 = [
    ['GESTOR ANTE EL TRIBUNAL DE ELECCIONES INTERNAS'],
  ];

  doc.autoTable({
    head: [block4],
    body: titleBlock4,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [221, 221, 221],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      fontWeight: 'bold',
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const headerblock4 = ['NOMBRE', 'CÉDULA'];
  const blankFields = [
    ['NOMBRE', gestorInfo.cedula],
    ['CÉDULA', gestorInfo.nombre],
  ];

  doc.autoTable({
    head: [headerblock4],
    body: blankFields,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [238, 238, 238],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [204, 204, 204],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const block5 = ['Total'];
  const titleBlock5 = [
    ['TOTAL A CANCELAR EN LA INSCRIPCIÓN'],
  ];

  doc.autoTable({
    head: [block5],
    body: titleBlock5,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [221, 221, 221],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      fontWeight: 'bold',
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const headerblock5 = ['NOMBRE', 'CÉDULA'];
  const blankFields2 = [
    ['INCRIPCIÓN DE PAPELETA', montosInfo.papeleta],
    ['MEMBRESÍAS', montosInfo.membresia],
    ['TOTAL', montosInfo.total],
  ];

  doc.autoTable({
    head: [headerblock5],
    body: blankFields2,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [238, 238, 238],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [204, 204, 204],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const fileTS = getTimeStamp();
  doc.save(
    `COMPROBANTE-DELEGADOS-PROVINCIALES-${fileTS}.pdf`,
  );
};

export const generateComprobanteComiteEjecutivo = (
  titleComprobante,
  generalInfo,
  candidatosInfo,
  gestorInfo,
  montosInfo,
) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('p', 'px', 'letter');

  const imgData = getLogoPLN();
  doc.addImage(imgData, 'JPEG', 30, 50);

  // ENCABEZADO DEL DOCUMENTO
  doc.setFont('times', 'bold');
  doc.text(200, 45, 'PARTIDO LIBERACIÓN NACIONAL');
  doc.setFontSize(12);
  doc.text(200, 60, 'Tribunal de Elecciones Internas');
  doc.setFont('times', 'normal');
  doc.text(200, 75, `${titleComprobante} Periodo 2021`);
  doc.setFont('times', 'normal');
  doc.text(200, 90, 'Comprobante de Inscripción');

  const columns = ['PROVINCIA', 'N° PAPELETA'];

  const datos1 = [
    [generalInfo.provincia, generalInfo.papeleta],
  ];

  const typeTable1 = 'grid';
  const showTheHeader = true;

  doc.autoTable({
    head: [columns],
    body: datos1,
    theme: typeTable1,
    startY: 110,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [238, 238, 238],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: showTheHeader,
    margin: { left: 20 },
  });

  const columns2 = ['Fecha', 'Consecutivo'];
  const datos2 = [
    [
      `FECHA INSCRIPCIÓN: ${generalInfo.fecha}`,
      `CONSECUTIVO: ${generalInfo.consecutivo}`,
    ],
  ];

  doc.autoTable({
    head: [columns2],
    body: datos2,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 0,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [204, 204, 204],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const block1 = ['Title'];
  const titleBlock = [
    ['CANDIDATOS(AS) AL COMITÉ EJECUTIVO PROVINCIAL'],
  ];

  doc.autoTable({
    head: [block1],
    body: titleBlock,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [221, 221, 221],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const headerblock1 = ['LUGAR', 'CEDULA', 'NOMBRE'];

  doc.autoTable({
    head: [headerblock1],
    body: candidatosInfo.delegados,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      2: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [238, 238, 238],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: true,
    margin: { left: 20 },
  });

  const block3 = ['Title'];
  const titleBlock3 = [['CANDIDATOS(AS) PARA LA FISCALÍA']];

  doc.autoTable({
    head: [block3],
    body: titleBlock3,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [221, 221, 221],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  doc.autoTable({
    head: [headerblock1],
    body: candidatosInfo.fiscales,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      2: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [238, 238, 238],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: true,
    margin: { left: 20 },
  });

  const block4 = ['Gestor'];
  const titleBlock4 = [
    ['GESTOR ANTE EL TRIBUNAL DE ELECCIONES INTERNAS'],
  ];

  doc.autoTable({
    head: [block4],
    body: titleBlock4,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [221, 221, 221],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      fontWeight: 'bold',
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const headerblock4 = ['NOMBRE', 'CÉDULA'];
  const blankFields = [
    ['NOMBRE', gestorInfo.cedula],
    ['CÉDULA', gestorInfo.nombre],
  ];

  doc.autoTable({
    head: [headerblock4],
    body: blankFields,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [238, 238, 238],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [204, 204, 204],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const block5 = ['Total'];
  const titleBlock5 = [
    ['TOTAL A CANCELAR EN LA INSCRIPCIÓN'],
  ];

  doc.autoTable({
    head: [block5],
    body: titleBlock5,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [221, 221, 221],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      fontWeight: 'bold',
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const headerblock5 = ['NOMBRE', 'CÉDULA'];
  const blankFields2 = [
    ['INCRIPCIÓN DE PAPELETA', montosInfo.papeleta],
    ['MEMBRESÍAS', montosInfo.membresia],
    ['TOTAL', montosInfo.total],
  ];

  doc.autoTable({
    head: [headerblock5],
    body: blankFields2,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [238, 238, 238],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [204, 204, 204],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const fileTS = getTimeStamp();
  doc.save(
    `COMPROBANTE-COMITE-EJECUTIVO-PROVINCIAL-${fileTS}.pdf`,
  );
};

export const generateComprobanteRepRegimenMunicipal = (
  titleComprobante,
  generalInfo,
  candidatosInfo,
  gestorInfo,
  montosInfo,
) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('p', 'px', 'letter');

  const imgData = getLogoPLN();
  doc.addImage(imgData, 'JPEG', 30, 50);

  // ENCABEZADO DEL DOCUMENTO
  doc.setFont('times', 'bold');
  doc.text(200, 45, 'PARTIDO LIBERACIÓN NACIONAL');
  doc.setFontSize(12);
  doc.text(200, 60, 'Tribunal de Elecciones Internas');
  doc.setFont('times', 'normal');
  doc.text(200, 75, `${titleComprobante} Periodo 2021`);
  doc.setFont('times', 'normal');
  doc.text(200, 90, 'Comprobante de Inscripción');

  const columns = ['PROVINCIA', 'N° PAPELETA'];

  const datos1 = [
    [generalInfo.provincia, generalInfo.papeleta],
  ];

  const typeTable1 = 'grid';
  const showTheHeader = true;

  doc.autoTable({
    head: [columns],
    body: datos1,
    theme: typeTable1,
    startY: 110,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      2: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      3: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
        fontSize: 30,
      },
    },
    headStyles: {
      fillColor: [238, 238, 238],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: showTheHeader,
    margin: { left: 20 },
  });

  const columns2 = ['Fecha', 'Consecutivo'];
  const datos2 = [
    [
      `FECHA INSCRIPCIÓN: ${generalInfo.fecha}`,
      `CONSECUTIVO: ${generalInfo.consecutivo}`,
    ],
  ];

  doc.autoTable({
    head: [columns2],
    body: datos2,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 0,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [204, 204, 204],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const block1 = ['Title'];
  const titleBlock = [
    ['CANDIDATOS(AS) A REPRESENTANTES REGIMEN MUNICIPAL'],
  ];

  doc.autoTable({
    head: [block1],
    body: titleBlock,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [221, 221, 221],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const headerblock1 = ['LUGAR', 'CEDULA', 'NOMBRE'];

  doc.autoTable({
    head: [headerblock1],
    body: candidatosInfo.delegados,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      2: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [238, 238, 238],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: true,
    margin: { left: 20 },
  });

  const block4 = ['Gestor'];
  const titleBlock4 = [
    ['GESTOR ANTE EL TRIBUNAL DE ELECCIONES INTERNAS'],
  ];

  doc.autoTable({
    head: [block4],
    body: titleBlock4,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [221, 221, 221],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      fontWeight: 'bold',
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const headerblock4 = ['NOMBRE', 'CÉDULA'];
  const blankFields = [
    ['NOMBRE', gestorInfo.cedula],
    ['CÉDULA', gestorInfo.nombre],
  ];

  doc.autoTable({
    head: [headerblock4],
    body: blankFields,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [238, 238, 238],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [204, 204, 204],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const block5 = ['Total'];
  const titleBlock5 = [
    ['TOTAL A CANCELAR EN LA INSCRIPCIÓN'],
  ];

  doc.autoTable({
    head: [block5],
    body: titleBlock5,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [221, 221, 221],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      fontWeight: 'bold',
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const headerblock5 = ['NOMBRE', 'CÉDULA'];
  const blankFields2 = [
    ['INCRIPCIÓN DE PAPELETA', montosInfo.papeleta],
    ['MEMBRESÍAS', montosInfo.membresia],
    ['TOTAL', montosInfo.total],
  ];

  doc.autoTable({
    head: [headerblock5],
    body: blankFields2,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        textColor: 0,
        fillColor: [238, 238, 238],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [204, 204, 204],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const fileTS = getTimeStamp();
  doc.save(
    `COMPROBANTE-REP-REGIMEN-MUNICIPAL-${fileTS}.pdf`,
  );
};

export const generateComprobantePresidenteRegimenMunicipal =
  (
    titleComprobante,
    generalInfo,
    candidatosInfo,
    gestorInfo,
    montosInfo,
  ) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('p', 'px', 'letter');

    const imgData = getLogoPLN();
    doc.addImage(imgData, 'JPEG', 30, 50);

    // ENCABEZADO DEL DOCUMENTO
    doc.setFont('times', 'bold');
    doc.text(200, 45, 'PARTIDO LIBERACIÓN NACIONAL');
    doc.setFontSize(12);
    doc.text(200, 60, 'Tribunal de Elecciones Internas');
    doc.setFont('times', 'normal');
    doc.text(200, 75, `${titleComprobante} 2021`);
    doc.setFont('times', 'normal');
    doc.text(200, 90, 'Comprobante de Inscripción');

    const typeTable1 = 'grid';

    const columns2 = ['Fecha', 'Consecutivo'];
    const datos2 = [
      [
        `FECHA INSCRIPCIÓN: ${generalInfo.fecha}`,
        `CONSECUTIVO: ${generalInfo.consecutivo}`,
      ],
    ];

    doc.autoTable({
      head: [columns2],
      body: datos2,
      theme: typeTable1,
      startY: 110,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'center',
          valign: 'middle',
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          valign: 'middle',
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: false,
      margin: { left: 20 },
    });

    const block1 = ['Title'];
    const titleBlock = [
      [`PRESIDENTE DE RÉGIMEN MUNICIPAL`],
    ];

    doc.autoTable({
      head: [block1],
      body: titleBlock,
      theme: typeTable1,
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'center',
          valign: 'middle',
          fontStyle: 'bold',
          textColor: 0,
          fillColor: [221, 221, 221],
        },
      },
      headStyles: {
        fillColor: [221, 221, 221],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: false,
      margin: { left: 20 },
    });

    const headerblock1 = ['LUGAR', 'CEDULA', 'NOMBRE'];

    doc.autoTable({
      head: [headerblock1],
      body: candidatosInfo.comite,
      theme: typeTable1,
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'center',
          valign: 'middle',
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          valign: 'middle',
          fillColor: [255, 255, 255],
        },
        2: {
          halign: 'center',
          valign: 'middle',
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [238, 238, 238],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: true,
      margin: { left: 20 },
    });

    const block4 = ['Gestor'];
    const titleBlock4 = [
      ['GESTOR ANTE EL TRIBUNAL DE ELECCIONES INTERNAS'],
    ];

    doc.autoTable({
      head: [block4],
      body: titleBlock4,
      theme: typeTable1,
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'center',
          valign: 'middle',
          fontStyle: 'bold',
          textColor: 0,
          fillColor: [221, 221, 221],
        },
      },
      headStyles: {
        fillColor: [221, 221, 221],
        fontSize: 9,
        textColor: 0,
        fontWeight: 'bold',
        halign: 'center',
      },
      showHead: false,
      margin: { left: 20 },
    });

    const headerblock4 = ['NOMBRE', 'CÉDULA'];
    const blankFields = [
      ['NOMBRE', gestorInfo.cedula],
      ['CÉDULA', gestorInfo.nombre],
    ];

    doc.autoTable({
      head: [headerblock4],
      body: blankFields,
      theme: typeTable1,
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'center',
          valign: 'middle',
          fontStyle: 'bold',
          textColor: 0,
          fillColor: [238, 238, 238],
        },
        1: {
          halign: 'center',
          valign: 'middle',
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: false,
      margin: { left: 20 },
    });

    const block5 = ['Total'];
    const titleBlock5 = [
      ['TOTAL A CANCELAR EN LA INSCRIPCIÓN'],
    ];

    doc.autoTable({
      head: [block5],
      body: titleBlock5,
      theme: typeTable1,
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'center',
          valign: 'middle',
          fontStyle: 'bold',
          textColor: 0,
          fillColor: [221, 221, 221],
        },
      },
      headStyles: {
        fillColor: [221, 221, 221],
        fontSize: 9,
        textColor: 0,
        fontWeight: 'bold',
        halign: 'center',
      },
      showHead: false,
      margin: { left: 20 },
    });

    const headerblock5 = ['NOMBRE', 'CÉDULA'];
    const blankFields2 = [
      ['INCRIPCIÓN DE PAPELETA', montosInfo.papeleta],
      ['MEMBRESÍAS', montosInfo.membresia],
      ['TOTAL', montosInfo.total],
    ];

    doc.autoTable({
      head: [headerblock5],
      body: blankFields2,
      theme: typeTable1,
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'center',
          valign: 'middle',
          fontStyle: 'bold',
          textColor: 0,
          fillColor: [238, 238, 238],
        },
        1: {
          halign: 'center',
          valign: 'middle',
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: false,
      margin: { left: 20 },
    });

    const fileTS = getTimeStamp();
    doc.save(
      `COMPROBANTE-PRESIDENTE-REGIMEN-MUNICIPAL-${fileTS}.pdf`,
    );
  };

export const generatePrevencion = (
  idPrevencion,
  dataPapeleta,
  gestorInfo,
) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('p', 'px', 'letter');

  const imgData = getLogoPLN();
  doc.addImage(imgData, 'JPEG', 30, 50);

  // ENCABEZADO DEL DOCUMENTO
  doc.setFont('times', 'bold');
  doc.text(200, 45, 'PARTIDO LIBERACIÓN NACIONAL');
  doc.setFontSize(12);
  doc.text(200, 60, 'Tribunal de Elecciones Internas');
  doc.setFont('times', 'normal');
  doc.text(200, 75, `PREVENCIÓN DE SUBSANACIONES`);
  doc.setFont('times', 'bold');
  doc.text(200, 90, `Prevención: ${idPrevencion}`);
  doc.setFont('times', 'normal');

  const blockText = ['Title'];
  const titleText = [
    [
      `Se procede a PREVENIR la subsanación de vicios, errores u omisiones en el proceso de inscripción de candidaturas de manera automática. Se le informa al (la) señor(a) gestor(a):`,
    ],
  ];

  doc.autoTable({
    head: [blockText],
    body: titleText,
    theme: 'plain',
    startY: 115,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'auto',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'left',
        valign: 'middle',
        fontStyle: 'normal',
        overflow: 'linebreak',
        cellWidth: 'auto',
        fontSize: 12,
        textColor: 0,
        fillColor: [256, 256, 256],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      halign: 'left',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const typeTable1 = 'grid';

  const columns2 = ['CEDULA', 'NOMBRE'];
  const datos2 = [[gestorInfo.cedula, gestorInfo.nombre]];

  doc.autoTable({
    head: [columns2],
    body: datos2,
    theme: typeTable1,
    startY: 145,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [204, 204, 204],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: true,
    margin: { left: 20 },
  });

  const block1 = ['Title'];
  const titleBlock = [
    [
      `Que, verificada la documentación respectiva, ha sido detectado el faltante de los siguientes requisitos en la inscripción:`,
    ],
  ];

  doc.autoTable({
    head: [block1],
    body: titleBlock,
    theme: 'plain',
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'auto',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'left',
        valign: 'middle',
        fontStyle: 'normal',
        overflow: 'linebreak',
        cellWidth: 'auto',
        fontSize: 12,
        textColor: 0,
        fillColor: [256, 256, 256],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      halign: 'left',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const headerblock1 = [
    'PAPELETA',
    'CONSECUTIVO',
    'NOTIFICACIÓN',
  ];

  const papeletaInfo = [
    [
      dataPapeleta.title,
      dataPapeleta.consecutivo,
      dataPapeleta.fecha,
    ],
  ];

  doc.autoTable({
    head: [headerblock1],
    body: papeletaInfo,
    theme: typeTable1,
    startY: doc.lastAutoTable.finalY,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      1: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      2: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
    },
    headStyles: {
      fillColor: [238, 238, 238],
      fontSize: 9,
      textColor: 0,
      halign: 'center',
    },
    showHead: true,
    margin: { left: 20 },
  });

  const block4 = ['MID TITLE'];
  const titleBlock4 = [
    [
      'SE HALLÓ QUE: Se debe registrar depósito de la inscripción.',
    ],
  ];

  doc.autoTable({
    head: [block4],
    body: titleBlock4,
    theme: 'plain',
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'wrap',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        fontStyle: 'bold',
        fontSize: 12,
        textColor: 0,
        fillColor: [256, 256, 256],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      fontWeight: 'bold',
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const block5 = ['FINAL TEXT'];
  const titleBlock5 = [
    [
      'Se tendrá por notificada esta prevención a la persona que materialmente solicite la inscripción, sin importar que sea o no el gestor de la candidatura. Se advierte que la omisión de cumplir con lo aquí prevenido, o su cumplimiento fuera del plazo especificado (3 días hábiles al terminar el periodo de inscripciones), conlleva la denegatoria de la inscripción de la candidatura.',
    ],
  ];

  doc.autoTable({
    head: [block5],
    body: titleBlock5,
    theme: 'plain',
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'auto',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'left',
        valign: 'middle',
        cellWidth: 'auto',
        fontStyle: 'normal',
        fontSize: 12,
        textColor: 0,
        fillColor: [256, 256, 256],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      fontWeight: 'bold',
      halign: 'left',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const blockFooter = ['Footer'];
  const titleBlockFooter = [
    [
      'PLNDigital.com 2021 Email: consultas@plndigital.com PLN; Mata Redonda, 125m O. del edificio del M.A.G., Casa Liberacionista "José Figueres Ferrer" Tel: (506)2549-5500. Aptdo:10051-1000 San José. Desarrollado por Corporación DSI S.A. http://www.corpodsi.com',
    ],
  ];

  doc.autoTable({
    head: [blockFooter],
    body: titleBlockFooter,
    theme: 'plain',
    startY: doc.lastAutoTable.finalY + 200,
    styles: {
      overflow: 'linebreak',
      cellWidth: 'auto',
      font: 'times',
    },
    columnStyles: {
      0: {
        halign: 'center',
        valign: 'middle',
        cellWidth: 'auto',
        fontStyle: 'normal',
        fontSize: 9,
        textColor: 0,
        fillColor: [256, 256, 256],
      },
    },
    headStyles: {
      fillColor: [221, 221, 221],
      fontSize: 9,
      textColor: 0,
      fontWeight: 'bold',
      halign: 'center',
    },
    showHead: false,
    margin: { left: 20 },
  });

  const fileTS = getTimeStamp();
  doc.save(`COMPROBANTE-PREVENCION-${fileTS}.pdf`);
};

export const generateGafete = (
  title,
  dataGafete,
  typeGafete,
) => {
  const imgData = getLogoPLN();
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('l', 'px', 'letter');

  const nestedTableCell = {
    content: '',
    styles: {
      minCellHeight: 165,
    },
  };

  const gafetesXHoja = splitInGroupOf(dataGafete, 4);

  // console.log(gafetesXHoja);

  const infoGafetes = gafetesXHoja;
  const totalPaginas = infoGafetes.length - 1;
  for (let pG = 0; pG < infoGafetes.length; pG++) {
    const pageXGafete = infoGafetes[pG];
    // console.log(pageXGafete);
    let columnsGafete = [['A']];
    let tableWG = 267;
    if (pageXGafete.length >= 2) {
      columnsGafete = [['A', 'B']];
      tableWG = 534;
    }
    doc.autoTable({
      theme: 'grid',
      head: columnsGafete,
      body: [[nestedTableCell]],
      foot: columnsGafete,
      startY: 20,
      showHead: false,
      showFoot: false,
      tableWidth: tableWG,
      didDrawCell: dataG => {
        if (
          dataG.row.index === 0 &&
          dataG.row.section === 'body'
        ) {
          const titleText = [
            [`PARTIDO LIBERACIÓN NACIONAL`],
          ];
          doc.addImage(
            imgData,
            'JPEG',
            dataG.cell.x + 95,
            dataG.cell.y + 5,
            75,
            22,
          );

          doc.autoTable({
            startY: dataG.cell.y + 35,
            margin: { left: dataG.cell.x + 2 },
            tableWidth: dataG.cell.width - 4,
            styles: {
              maxCellHeight: 4,
              halign: 'center',
              fontStyle: 'bold',
            },
            columnStyles: {
              0: {
                fillColor: [255, 255, 255],
              },
            },
            columns: [
              { dataKey: 'Title', header: 'Title' },
            ],
            body: titleText,
            showHead: false,
          });

          doc.autoTable({
            startY: doc.lastAutoTable.finalY,
            margin: { left: dataG.cell.x + 2 },
            tableWidth: dataG.cell.width - 4,
            styles: {
              maxCellHeight: 4,
              halign: 'center',
            },
            columns: [{ dataKey: 'Name', header: 'Name' }],
            columnStyles: {
              0: {
                fillColor: [255, 255, 255],
                padding: { bottom: 0 },
              },
            },
            body: [
              [pageXGafete[dataG.column.index].nombre],
            ],
            showHead: false,
          });

          doc.autoTable({
            startY: doc.lastAutoTable.finalY,
            margin: { left: dataG.cell.x + 2 },
            tableWidth: dataG.cell.width - 4,
            styles: {
              maxCellHeight: 4,
              fontStyle: 'bold',
              halign: 'center',
            },
            columns: [
              {
                dataKey: 'separator1',
                header: 'separator1',
              },
            ],
            columnStyles: {
              0: {
                fillColor: [255, 255, 255],
                fontSize: 8,
                halign: 'left',
                cellPadding: 0,
                margin: { left: 40 },
              },
            },
            body: [
              [
                '                           ¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯',
              ],
            ],
            showHead: false,
          });

          doc.autoTable({
            startY: doc.lastAutoTable.finalY,
            margin: { left: dataG.cell.x + 2 },
            tableWidth: dataG.cell.width - 4,
            styles: {
              maxCellHeight: 4,
              fontStyle: 'bold',
              halign: 'center',
            },
            columns: [
              { dataKey: 'NameLabel', header: 'NameLabel' },
            ],
            columnStyles: {
              0: {
                fillColor: [255, 255, 255],
                cellPadding: 0,
                fontSize: 8,
              },
            },
            body: [[`Nombre ${title}`]],
            showHead: false,
          });

          doc.autoTable({
            startY: doc.lastAutoTable.finalY,
            margin: { left: dataG.cell.x + 2 },
            tableWidth: dataG.cell.width - 4,
            styles: {
              maxCellHeight: 4,
              fontStyle: 'bold',
              halign: 'center',
            },
            columns: [
              {
                dataKey: 'CedulaLabel',
                header: 'CedulaLabel',
              },
            ],
            columnStyles: {
              0: {
                fillColor: [255, 255, 255],
              },
            },
            body: [
              [
                `Cédula: ${
                  pageXGafete[dataG.column.index].cedula
                }`,
              ],
            ],
            showHead: false,
          });

          // Cuando el tipo de gafete no es para Fiscal General
          if (typeGafete !== 3) {
            doc.autoTable({
              startY: doc.lastAutoTable.finalY + 5,
              margin: { left: dataG.cell.x + 2 },
              tableWidth: dataG.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                halign: 'center',
                cellPadding: 0,
              },
              columns: [
                { dataKey: 'Tipo', header: 'Tipo' },
                { dataKey: 'Junta', header: 'Junta' },
                { dataKey: 'Distrito', header: 'Distrito' },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  padding: { bottom: 0 },
                },
                1: {
                  fillColor: [255, 255, 255],
                  padding: { bottom: 0 },
                },
                2: {
                  fillColor: [255, 255, 255],
                  padding: { bottom: 0 },
                },
              },
              body: [
                [
                  pageXGafete[dataG.column.index].puesto,
                  `${pageXGafete[dataG.column.index].jrv}`,
                  pageXGafete[dataG.column.index].distrito,
                ],
                ['TIPO', 'JUNTA N°', 'DISTRITO'],
              ],
              showHead: false,
            });
          }

          doc.autoTable({
            startY: doc.lastAutoTable.finalY + 10,
            margin: { left: dataG.cell.x + 2 },
            tableWidth: dataG.cell.width - 4,
            styles: {
              maxCellHeight: 4,
              halign: 'center',
            },
            columns: [{ dataKey: 'Name', header: 'Name' }],
            columnStyles: {
              0: {
                fillColor: [255, 255, 255],
                padding: { bottom: 0 },
              },
            },
            body: [['6 de Junio 2021']],
            showHead: false,
          });

          doc.autoTable({
            startY: doc.lastAutoTable.finalY,
            margin: { left: dataG.cell.x + 2 },
            tableWidth: dataG.cell.width - 4,
            styles: {
              maxCellHeight: 4,
              fontStyle: 'bold',
              halign: 'center',
            },
            columns: [
              {
                dataKey: 'separator2',
                header: 'separator2',
              },
            ],
            columnStyles: {
              0: {
                fillColor: [255, 255, 255],
                fontSize: 8,
                halign: 'left',
                cellPadding: 0,
                margin: { left: 40 },
              },
            },
            body: [
              [
                '                                        ¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯',
              ],
            ],
            showHead: false,
          });

          doc.autoTable({
            startY: doc.lastAutoTable.finalY,
            margin: { left: dataG.cell.x + 2 },
            tableWidth: dataG.cell.width - 4,
            styles: {
              maxCellHeight: 4,
              fontStyle: 'bold',
              halign: 'center',
            },
            columns: [
              { dataKey: 'NameLabel', header: 'NameLabel' },
            ],
            columnStyles: {
              0: {
                fillColor: [255, 255, 255],
                fontSize: 8,
                cellPadding: 0,
              },
            },
            body: [['Fecha de la elección']],
            showHead: false,
          });
        }
      },
    });

    if (pageXGafete.length > 3) {
      doc.autoTable({
        startY: doc.lastAutoTable.finalY,
        theme: 'grid',
        head: [['2019', '2020']],
        body: [[nestedTableCell]],
        foot: [['2019', '2020']],
        showHead: false,
        showFoot: false,
        didDrawCell(data) {
          if (
            data.row.index === 0 &&
            data.row.section === 'body'
          ) {
            const titleText = [
              [`PARTIDO LIBERACIÓN NACIONAL`],
            ];
            doc.addImage(
              imgData,
              'JPEG',
              data.cell.x + 95,
              data.cell.y + 5,
              75,
              22,
            );

            doc.autoTable({
              startY: data.cell.y + 35,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                halign: 'center',
                fontStyle: 'bold',
              },
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                },
              },
              columns: [
                { dataKey: 'Title', header: 'Title' },
              ],
              body: titleText,
              showHead: false,
            });

            doc.autoTable({
              startY: doc.lastAutoTable.finalY,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                halign: 'center',
              },
              columns: [
                { dataKey: 'Name', header: 'Name' },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  padding: { bottom: 0 },
                },
              },
              body: [
                [pageXGafete[data.column.index + 2].nombre],
              ],
              showHead: false,
            });

            doc.autoTable({
              startY: doc.lastAutoTable.finalY,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                fontStyle: 'bold',
                halign: 'center',
              },
              columns: [
                {
                  dataKey: 'separator1',
                  header: 'separator1',
                },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  fontSize: 8,
                  halign: 'left',
                  cellPadding: 0,
                  margin: { left: 40 },
                },
              },
              body: [
                [
                  '                           ¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯',
                ],
              ],
              showHead: false,
            });

            doc.autoTable({
              startY: doc.lastAutoTable.finalY,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                fontStyle: 'bold',
                halign: 'center',
              },
              columns: [
                {
                  dataKey: 'NameLabel',
                  header: 'NameLabel',
                },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  cellPadding: 0,
                  fontSize: 8,
                },
              },
              body: [[`Nombre ${title}`]],
              showHead: false,
            });

            doc.autoTable({
              startY: doc.lastAutoTable.finalY,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                fontStyle: 'bold',
                halign: 'center',
              },
              columns: [
                {
                  dataKey: 'CedulaLabel',
                  header: 'CedulaLabel',
                },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                },
              },
              body: [
                [
                  `Cédula: ${
                    pageXGafete[data.column.index + 2]
                      .cedula
                  }`,
                ],
              ],
              showHead: false,
            });
            // Cuando el tipo de gafete no es para Fiscal General
            if (typeGafete !== 3) {
              doc.autoTable({
                startY: doc.lastAutoTable.finalY + 5,
                margin: { left: data.cell.x + 2 },
                tableWidth: data.cell.width - 4,
                styles: {
                  maxCellHeight: 4,
                  halign: 'center',
                  cellPadding: 0,
                },
                columns: [
                  { dataKey: 'Tipo', header: 'Tipo' },
                  { dataKey: 'Junta', header: 'Junta' },
                  {
                    dataKey: 'Distrito',
                    header: 'Distrito',
                  },
                ],
                columnStyles: {
                  0: {
                    fillColor: [255, 255, 255],
                    padding: { bottom: 0 },
                  },
                  1: {
                    fillColor: [255, 255, 255],
                    padding: { bottom: 0 },
                  },
                  2: {
                    fillColor: [255, 255, 255],
                    padding: { bottom: 0 },
                  },
                },
                body: [
                  [
                    pageXGafete[data.column.index + 2]
                      .puesto,
                    `${
                      pageXGafete[data.column.index + 2].jrv
                    }`,
                    pageXGafete[data.column.index + 2]
                      .distrito,
                  ],
                  ['TIPO', 'JUNTA N°', 'DISTRITO'],
                ],
                showHead: false,
              });
            }
            doc.autoTable({
              startY: doc.lastAutoTable.finalY + 10,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                halign: 'center',
              },
              columns: [
                { dataKey: 'Name', header: 'Name' },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  padding: { bottom: 0 },
                },
              },
              body: [['6 de Junio 2021']],
              showHead: false,
            });

            doc.autoTable({
              startY: doc.lastAutoTable.finalY,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                fontStyle: 'bold',
                halign: 'center',
              },
              columns: [
                {
                  dataKey: 'separator2',
                  header: 'separator2',
                },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  fontSize: 8,
                  halign: 'left',
                  cellPadding: 0,
                  margin: { left: 40 },
                },
              },
              body: [
                [
                  '                                        ¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯',
                ],
              ],
              showHead: false,
            });

            doc.autoTable({
              startY: doc.lastAutoTable.finalY,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                fontStyle: 'bold',
                halign: 'center',
              },
              columns: [
                {
                  dataKey: 'NameLabel',
                  header: 'NameLabel',
                },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  fontSize: 8,
                  cellPadding: 0,
                },
              },
              body: [['Fecha de la elección']],
              showHead: false,
            });
          }
        },
      });
    } else if (pageXGafete.length === 3) {
      doc.autoTable({
        startY: doc.lastAutoTable.finalY,
        theme: 'grid',
        head: [['A']],
        body: [[nestedTableCell]],
        foot: [['A']],
        showHead: false,
        showFoot: false,
        tableWidth: 267,
        didDrawCell(data) {
          if (
            data.row.index === 0 &&
            data.row.section === 'body'
          ) {
            const titleText = [
              [`PARTIDO LIBERACIÓN NACIONAL`],
            ];
            doc.addImage(
              imgData,
              'JPEG',
              data.cell.x + 95,
              data.cell.y + 5,
              75,
              22,
            );

            doc.autoTable({
              startY: data.cell.y + 35,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                halign: 'center',
                fontStyle: 'bold',
              },
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                },
              },
              columns: [
                { dataKey: 'Title', header: 'Title' },
              ],
              body: titleText,
              showHead: false,
            });

            doc.autoTable({
              startY: doc.lastAutoTable.finalY,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                halign: 'center',
              },
              columns: [
                { dataKey: 'Name', header: 'Name' },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  padding: { bottom: 0 },
                },
              },
              body: [
                [pageXGafete[data.column.index + 2].nombre],
              ],
              showHead: false,
            });

            doc.autoTable({
              startY: doc.lastAutoTable.finalY,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                fontStyle: 'bold',
                halign: 'center',
              },
              columns: [
                {
                  dataKey: 'separator1',
                  header: 'separator1',
                },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  fontSize: 8,
                  halign: 'left',
                  cellPadding: 0,
                  margin: { left: 40 },
                },
              },
              body: [
                [
                  '                           ¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯',
                ],
              ],
              showHead: false,
            });

            doc.autoTable({
              startY: doc.lastAutoTable.finalY,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                fontStyle: 'bold',
                halign: 'center',
              },
              columns: [
                {
                  dataKey: 'NameLabel',
                  header: 'NameLabel',
                },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  cellPadding: 0,
                  fontSize: 8,
                },
              },
              body: [[`Nombre ${title}`]],
              showHead: false,
            });

            doc.autoTable({
              startY: doc.lastAutoTable.finalY,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                fontStyle: 'bold',
                halign: 'center',
              },
              columns: [
                {
                  dataKey: 'CedulaLabel',
                  header: 'CedulaLabel',
                },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                },
              },
              body: [
                [
                  `Cédula: ${
                    pageXGafete[data.column.index + 2]
                      .cedula
                  }`,
                ],
              ],
              showHead: false,
            });

            // Cuando el tipo de gafete no es para Fiscal General
            if (typeGafete !== 3) {
              doc.autoTable({
                startY: doc.lastAutoTable.finalY + 5,
                margin: { left: data.cell.x + 2 },
                tableWidth: data.cell.width - 4,
                styles: {
                  maxCellHeight: 4,
                  halign: 'center',
                  cellPadding: 0,
                },
                columns: [
                  { dataKey: 'Tipo', header: 'Tipo' },
                  { dataKey: 'Junta', header: 'Junta' },
                  {
                    dataKey: 'Distrito',
                    header: 'Distrito',
                  },
                ],
                columnStyles: {
                  0: {
                    fillColor: [255, 255, 255],
                    padding: { bottom: 0 },
                  },
                  1: {
                    fillColor: [255, 255, 255],
                    padding: { bottom: 0 },
                  },
                  2: {
                    fillColor: [255, 255, 255],
                    padding: { bottom: 0 },
                  },
                },
                body: [
                  [
                    pageXGafete[data.column.index + 2]
                      .puesto,
                    `${
                      pageXGafete[data.column.index + 2].jrv
                    }`,
                    pageXGafete[data.column.index + 2]
                      .distrito,
                  ],
                  ['TIPO', 'JUNTA N°', 'DISTRITO'],
                ],
                showHead: false,
              });
            }

            doc.autoTable({
              startY: doc.lastAutoTable.finalY + 10,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                halign: 'center',
              },
              columns: [
                { dataKey: 'Name', header: 'Name' },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  padding: { bottom: 0 },
                },
              },
              body: [['6 de Junio 2021']],
              showHead: false,
            });

            doc.autoTable({
              startY: doc.lastAutoTable.finalY,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                fontStyle: 'bold',
                halign: 'center',
              },
              columns: [
                {
                  dataKey: 'separator2',
                  header: 'separator2',
                },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  fontSize: 8,
                  halign: 'left',
                  cellPadding: 0,
                  margin: { left: 40 },
                },
              },
              body: [
                [
                  '                                        ¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯',
                ],
              ],
              showHead: false,
            });

            doc.autoTable({
              startY: doc.lastAutoTable.finalY,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                fontStyle: 'bold',
                halign: 'center',
              },
              columns: [
                {
                  dataKey: 'NameLabel',
                  header: 'NameLabel',
                },
              ],
              columnStyles: {
                0: {
                  fillColor: [255, 255, 255],
                  fontSize: 8,
                  cellPadding: 0,
                },
              },
              body: [['Fecha de la elección']],
              showHead: false,
            });
          }
        },
      });
    }

    if (pG < totalPaginas) {
      doc.addPage();
    }
  }

  //  doc.addPage();

  const fileTS = getTimeStamp();
  doc.save(
    `GAFETES-${title.replace(' ', '-')}-${fileTS}.pdf`,
  );
};
