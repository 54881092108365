import Lodash from 'lodash';

const matchClass = (className, classToSearch) => {
  // const patt = new RegExp(`((${classToSearch})-([0-9]+)*)`);
  // const res = patt.test(className);
  return className.includes(classToSearch);
};

const getUrlParams = url => {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const params = {};
  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(url))) {
    // eslint-disable-next-line prefer-destructuring
    params[match[1]] = match[2];
  }
  return params;
};

export const formatData = _total => {
  return _total
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getEstadoPapeleta = codStatus => {
  const estadosPapeleta = [
    { id: 2, description: 'Registrada' },
    { id: 3, description: 'Confección' },
    { id: 4, description: 'Prevención' },
    { id: 5, description: 'Anulada' },
  ];

  const estado = Lodash.find(estadosPapeleta, {
    id: codStatus,
  });

  return codStatus ? estado.description : '';
};

export const urlHasResetCode = url => {
  const params = getUrlParams(url);
  const { oobCode } = params;
  if (oobCode !== undefined && oobCode.length > 0) {
    return true;
  }
  return false;
};

export const urlResetCode = url => {
  const params = getUrlParams(url);
  const { oobCode } = params;
  if (oobCode !== undefined && oobCode.length > 0) {
    return oobCode;
  }
  return '';
};

export const formatDate = date => {
  const dateParts = date.split('/');
  const month = dateParts[1];
  const day = dateParts[0];
  const year = dateParts[2];

  return [year, month, day].join('-');
};

export const splitInGroupOf = (arr, len) => {
  const chunks = [];
  let i = 0;
  const n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
};

export default matchClass;
