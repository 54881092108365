/* eslint-disable max-len */
/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import Lodash from 'lodash';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Button } from '@material-ui/core';
import style from './styles';

import { useProvincias } from '../../Core/Api/locationApi';

const FRMPresidenteRegimenMunicipal = props => {
  const { classes } = props;
  const { data } = useProvincias();

  const doc = new jsPDF('p', 'px', 'letter');

  // eslint-disable-next-line no-unused-vars
  const [sCanton, setSCanton] = useState(999);

  const provincias = Lodash.get(
    data,
    ['frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE LA PROVINCIA -',
        value: 9,
      },
    ],
  );

  const tableHeaderTitleCF = ['PROPIETARIO'];

  const getTimeStamp = () => {
    const now = new Date();
    return `${
      now.getMonth() + 1
    }/${now.getDate()}/${now.getFullYear()} ${now.getHours()}:${
      now.getMinutes() < 10
        ? `0${now.getMinutes()}`
        : now.getMinutes()
    }:${
      now.getSeconds() < 10
        ? `0${now.getSeconds()}`
        : now.getSeconds()
    }`;
  };

  const centeredText = (text, y) => {
    const textWidth =
      (doc.getStringUnitWidth(text) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textOffset =
      (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, y, text);
  };

  const getNotesPDF = () => {
    let notesData = [];

    notesData = [
      [
        // eslint-disable-next-line max-len
        `* No se aceptarán inscripciones con documentación o número de candidatos incompletos.`,
      ],
      [
        // eslint-disable-next-line max-len
        `* El gestor(a) es ahora el representante de la papeleta contenida en este formulario ante el T.E.I., para todos los efectos de su inscripción y tramitación.`,
      ],
    ];

    return notesData;
  };

  const generatePDF = () => {
    // ENCABEZADO DEL DOCUMENTO
    doc.setFont('times', 'bold');
    doc.text(
      20,
      40,
      process.env.REACT_APP_PDF_PARTIDO_REPORTS,
    );
    doc.setFontSize(12);
    doc.text(20, 60, 'Asambleas Provinciales');
    doc.setFont('times', 'normal');
    doc.text(
      20,
      80,
      process.env.REACT_APP_PDF_PERIODO_REPORTS,
    );

    doc.setFont('times', 'bold');

    centeredText(
      process.env.REACT_APP_PDF_TITLE_FORMULARIO_REPORTS,
      100,
    );

    doc.setFont('times', 'normal');
    doc.text(20, 120, 'Señores');
    doc.text(
      20,
      130,
      process.env.REACT_APP_PDF_TITLE_TRIBUNAL_REPORTS,
    );

    let paragraph = `El(la) suscrito(a), abajo firmante, miembro activo del Partido, de conformidad con lo dispuesto en el Reglamento para la Elección de Representantes del Régimen Municipal, solicito la inscripción de la siguiente candidatura para participar en la escogencia del Presidente del Régimen Municipal.`;
    let lines = doc.splitTextToSize(paragraph, 415);
    doc.text(20, 150, lines);

    const baseHeightText = 190;
    const baseHeightTable = 85;

    doc.setFontSize(12);
    doc.setFont('times', 'normal');
    // eslint-disable-next-line max-len
    paragraph = `Declaró incondicionalmente mi adhesión al Partido y a sus principios ideológicos y programáticos y acepto en su totalidad las normas ético-jurídicas que rigen el proceso, así como las decisiones, resoluciones y acuerdos del Tribunal de Elecciones Internas. Igualmente me comprometo a contribuir económicamente al mantenimiento del Partido, según lo dispuesto en el Articulo 14, inciso a) del Estatuto:`;

    lines = doc.splitTextToSize(paragraph, 425);
    doc.text(20, baseHeightText, lines);

    const theTitle =
      'CANDIDATOS(AS) PARA EL COMITÉ EJECUTIVO PROVINCIAL';

    doc.setFont('times', 'bold');
    centeredText(theTitle, 280);

    let posY = doc.lastAutoTable.finalY + baseHeightTable;

    // TABLA: CANDIDATOS FISCALÍA
    const columnsT2 = ['A'];
    const dataT2 = [[`PRESIDENTE DEL RÉGIMEN MUNICIPAL`]];

    doc.autoTable({
      head: [columnsT2],
      body: dataT2,
      theme: 'plain',
      startY: 230,
      styles: {
        font: 'times',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    posY = doc.lastAutoTable.finalY + 5;

    for (let n3 = 0; n3 < tableHeaderTitleCF.length; n3++) {
      if (n3 !== 0) {
        posY = doc.lastAutoTable.finalY + 5;
      }

      const columns2 = [
        'CÉDULA',
        'PRIMER APELLIDO',
        'SEGUNDO APELLIDO',
        'NOMBRE',
      ];
      const datos2 = [['', '', '', '', '']];

      // posY += 15;
      doc.autoTable({
        head: [columns2],
        body: datos2,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: {
          overflow: 'linebreak',
          cellWidth: 'wrap',
          font: 'times',
        },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
          1: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
          2: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
          3: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        margin: { left: 20 },
      });

      const columns3 = ['A'];
      const data3 = [
        [
          // eslint-disable-next-line max-len
          `En este acto con mi firma de puño y letra, ratifico mi aceptación como candidato(a). Asimismo autorizo al gestor(a) para que deposite lo correspondiente a mi cuota de membrecía.`,
        ],
      ];

      // posY += 45;
      doc.autoTable({
        head: [columns3],
        body: data3,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: { font: 'times' },
        columnStyles: {
          0: {
            halign: 'left',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });

      const columns4 = ['', ''];
      const data4 = [
        [
          '                        FIRMA                            ',
          '_____________________________________________',
        ],
      ];

      // posY += 70;
      doc.autoTable({
        head: [columns4],
        body: data4,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: { font: 'times', color: '#000' },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
          1: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });
    }

    // TABLA: GESTOR DE LA PAPELETA
    const columnsT3 = ['A'];
    const dataT3 = [
      [
        `GESTOR DE LA PAPELETA ANTE EL TRIBUNAL DE ELECCIONES INTERNAS`,
      ],
    ];

    doc.autoTable({
      head: [columnsT3],
      body: dataT3,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        font: 'times',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    posY = doc.lastAutoTable.finalY + 5;

    const columnsT4 = ['A', 'B'];
    const dataT4 = [
      [
        'CÉDULA',
        '                                             ',
      ],
      [
        'NOMBRE COMPLETO',
        '                                             ',
      ],
      [
        'FIRMA',
        '                                             ',
      ],
    ];

    doc.autoTable({
      head: [columnsT4],
      body: dataT4,
      theme: 'grid',
      startY: posY,
      styles: { font: 'times', fontStyle: 'bold' },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
        },
        1: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    posY = doc.lastAutoTable.finalY + 5;

    // TABLA: NOTAS
    const columnsT5 = ['A'];
    const dataT5 = [[`Notas`]];

    doc.autoTable({
      head: [columnsT5],
      body: dataT5,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        font: 'times',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsT6 = ['A'];
    const dataT6 = getNotesPDF();

    doc.autoTable({
      head: [columnsT6],
      body: dataT6,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 10,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    // FOOTER DOCUMENT
    const columnsPie = ['A'];
    const dataPie = [
      [
        // eslint-disable-next-line max-len
        `PLNDigital.com ${process.env.REACT_APP_PDF_YEAR_REPORTS} Email: consultas@plndigital.com PLN; Mata Redonda, 125m O. del edificio del M.A.G., Casa Liberacionista "José Figueres Ferrer" Tel: (506)2549­5500. Aptdo:10051­1000 San José. Sitio web desarrollado por Corporación DSI S.A.`,
      ],
    ];
    doc.autoTable({
      head: [columnsPie],
      body: dataPie,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY + 40,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 10,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const fileTS = getTimeStamp();
    doc.save(
      `formulario-presidennte-regimen-municipal-${fileTS}.pdf`,
    );
  };

  if (!Lodash.isEmpty(provincias)) {
    return (
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <h3>FORMULARIO PRESIDENTE DE RÉGIMEN MUNICIPAL</h3>
        <h4 className={classes.subtitle}>
          Descargar Formulario
        </h4>
        <div className={classes.boxForm}>
          <div className={classes.boxDocToPdf}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'left' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={generatePDF}
                  style={{ marginTop: 10 }}
                >
                  DESCARGAR FORMULARIO
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    );
  }
  return '';
};

FRMPresidenteRegimenMunicipal.propTypes = {};

export default withStyles(style)(
  FRMPresidenteRegimenMunicipal,
);
