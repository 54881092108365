import {
  colors,
  breakpoints as bp,
} from '../../Core/Theme/theme';

const styles = theme => ({
  container: {
    background: colors.backGray,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  imgLogo: {
    maxWidth: '100%',
    width: '245px',
    height: 'auto',
    display: 'block',
    padding: 15,
    [theme.breakpoints.down(bp.MOBILE)]: {
      paddingTop: 25,
    },
  },
  socialLinks: {
    padding: 0,
    marginTop: 25,
    marginRight: 25,
    float: 'right',
    '& li': {
      listStyle: 'none',
      float: 'left',
      marginRight: '10px',
    },
  },
  loginAccess: {
    padding: 0,
    marginTop: 25,
    color: '#999',
    marginRight: 25,
    float: 'right',
    '& li': {
      listStyle: 'none',
      float: 'left',
      marginRight: '10px',
      paddingLeft: '25px',
      borderLeft: 'solid thin #000',
      '& span': {
        float: 'right',
        marginLeft: 5,
        marginTop: 3,
      },
      '& a.MuiLink-root': {
        color: '#999',
        fontFamily: "'Inter'",
      },
    },
  },
});

export default styles;
