import axios from 'axios';
// import Lodash from 'lodash';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

// eslint-disable-next-line import/prefer-default-export
export const addPrevencion = params => {
  const { dataPrevencion } = params;

  const infoPrevencion = {
    prevencion: dataPrevencion,
  };

  return axios.post(
    '/prevencion/addPrevencionEP',
    infoPrevencion,
  );
};
