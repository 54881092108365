/* eslint-disable max-len */
/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Button } from '@material-ui/core';
import style from './styles';

const FRMHojaInformacion = props => {
  const { classes } = props;

  const doc = new jsPDF('p', 'px', 'letter');

  const getTimeStamp = () => {
    const now = new Date();
    return `${
      now.getMonth() + 1
    }/${now.getDate()}/${now.getFullYear()} ${now.getHours()}:${
      now.getMinutes() < 10
        ? `0${now.getMinutes()}`
        : now.getMinutes()
    }:${
      now.getSeconds() < 10
        ? `0${now.getSeconds()}`
        : now.getSeconds()
    }`;
  };

  const centeredText = (text, y) => {
    const textWidth =
      (doc.getStringUnitWidth(text) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textOffset =
      (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, y, text);
  };

  const generateHojaInformacion = () => {
    // ENCABEZADO DEL DOCUMENTO
    doc.setFont('times', 'bold');
    doc.text(
      20,
      40,
      process.env.REACT_APP_PDF_PARTIDO_REPORTS,
    );
    doc.setFontSize(12);
    doc.text(20, 60, 'TRIBUNAL DE ELECCIONES INTERNAS');
    doc.setFont('times', 'normal');
    doc.text(
      20,
      80,
      process.env.REACT_APP_PDF_PERIODO_REPORTS,
    );

    doc.setFont('times', 'bold');
    centeredText(
      process.env.REACT_APP_PDF_TITLE_HOJA_INFO_REPORTS,
      100,
    );

    doc.setFont('times', 'bold');
    const columnsA = ['CÉDULA', ''];
    const dataA = [['CÉDULA', '']];

    doc.autoTable({
      head: [columnsA],
      body: dataA,
      theme: 'grid',
      startY: 120,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 130,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          cellWidth: 290,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsB = ['NOMBRE', ''];
    const dataB = [['NOMBRE', '']];

    doc.autoTable({
      head: [columnsB],
      body: dataB,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 130,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          cellWidth: 290,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsC = ['DIRECCION', ''];
    const dataC = [['DIRECCIÓN HABITACIÓN', '']];

    doc.autoTable({
      head: [columnsC],
      body: dataC,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 130,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          cellWidth: 290,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsD = [
      'TELEFONO HABITACIÓN',
      'CELULAR',
      'FAX',
      'OTRO',
    ];
    const dataD = [['', '', '', '']];

    doc.autoTable({
      head: [columnsD],
      body: dataD,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 130,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'left',
          cellWidth: 100,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        2: {
          halign: 'left',
          cellWidth: 95,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        3: {
          halign: 'left',
          cellWidth: 95,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      margin: { left: 20 },
    });

    const columnsE = ['DIRECCION TRABAJO', ''];
    const dataE = [['DIRECCIÓN TRABAJO', '']];

    doc.autoTable({
      head: [columnsE],
      body: dataE,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 130,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          cellWidth: 290,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsF = ['TELEFONO TRABAJO', 'FAX TRABAJO'];
    const dataF = [['', '']];

    doc.autoTable({
      head: [columnsF],
      body: dataF,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 230,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'left',
          cellWidth: 190,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      margin: { left: 20 },
    });

    const columnsG = [
      'CORREO ELECTRONICO OFICIAL PARA NOTIFICACIONES',
      '',
    ];
    const dataG = [
      [
        'CORREO ELECTRÓNICO OFICIAL PARA NOTIFICACIONES',
        '',
      ],
    ];

    doc.autoTable({
      head: [columnsG],
      body: dataG,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 130,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          cellWidth: 290,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsH = ['H'];
    const dataH = [
      [
        // eslint-disable-next-line max-len
        'En apego a la Ley No 8968 de Protección de la Persona frente al tratamiento de sus datos personales: Autorizo al Partido Liberación Nacional, para que utilice mi información, con el fin de que el PLN pueda mantener contacto con mi persona, ya sea por teléfono o por correo electrónico.',
      ],
    ];

    doc.autoTable({
      head: [columnsH],
      body: dataH,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 420,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsI = ['FIRMA'];
    const dataI = [
      ['____________________________________________'],
    ];

    doc.autoTable({
      head: [columnsI],
      body: dataI,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'center',
          cellWidth: 420,
          textColor: 0,
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      margin: { left: 20 },
    });

    // TABLA: NOTAS
    const columnsT5 = ['A'];
    const dataT5 = [[`Notas`]];

    doc.autoTable({
      head: [columnsT5],
      body: dataT5,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        font: 'times',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsT6 = ['A'];
    const dataT6 = [
      [
        // eslint-disable-next-line max-len
        `Para cada gestor(a) y candidato(a) se debe aportar un correo electrónico personal oficial para recibir notificaciones del T.E.I. o del T.S.E. (No se recomienda utilizar el mismo correo electrónico del gestor).`,
      ],
    ];

    doc.autoTable({
      head: [columnsT6],
      body: dataT6,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 10,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    // FOOTER DOCUMENT
    const columnsPie = ['A'];
    const dataPie = [
      [
        // eslint-disable-next-line max-len
        `PLNDigital.com ${process.env.REACT_APP_PDF_YEAR_REPORTS} Email: consultas@plndigital.com PLN; Mata Redonda, 125m O. del edificio del M.A.G., Casa Liberacionista "José Figueres Ferrer" Tel: (506)2549­5500. Aptdo:10051­1000 San José. Sitio web desarrollado por Corporación DSI S.A.`,
      ],
    ];
    doc.autoTable({
      head: [columnsPie],
      body: dataPie,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY + 50,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 10,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const fileTS = getTimeStamp();
    doc.save(`formulario-hoja-informacion-${fileTS}.pdf`);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <h3>FORMULARIO DE HOJA DE INFORMACIÓN</h3>
      <div className={classes.boxForm}>
        <div className={classes.boxDocToPdf}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{ textAlign: 'left' }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={generateHojaInformacion}
                style={{ marginTop: 10 }}
              >
                DESCARGAR FORMULARIO
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Container>
  );
};

FRMHojaInformacion.propTypes = {};

export default withStyles(style)(FRMHojaInformacion);
