import React, { useState, useEffect } from 'react';
import Lodash from 'lodash';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useJwt } from 'react-jwt';
import BlockIcon from '@material-ui/icons/Block';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import { tableHeaders } from './data';
import MacroList from '../MacroList/MacroList';
import presidenteRegimenMunicipalApi from '../../Core/Api/presidenteRegimenMunicipalApi';
import { getDataComprobante } from '../../Core/Api/comprobanteApi';
import Storage from '../../Storage/Storage';
import Consts from '../../Core/Consts/Consts';
import PLNDialog from '../../Components/PLNDialog/PLNDialog';

import { generateComprobantePresidenteRegimenMunicipal } from '../../Core/Utils/Comprobante';
import { useCRUDAvailable } from '../../Core/Api/periodoApi';

function createData(_data) {
  const filterEstado = Consts.estadosDistritales.filter(
    status => {
      return status.codigo === _data.cod_estado;
    },
  );

  return {
    consecutivo: _data.num_consecutivo,
    cedula: _data.num_cedula,
    primerApellido: _data.des_apell1,
    segundoApellido: _data.des_apell2,
    nombre: _data.des_nombre,
    cod_estado: filterEstado[0].estado,
    cod_papeleta: _data.cod_papeleta,
  };
}

const useStyles = makeStyles(() => ({
  btnAdd: {
    float: 'right',
    width: 150,
    height: 30,
    marginTop: 5,
  },
  container: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  paper: {
    padding: 20,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const PresidenteRegimenMunicipal = () => {
  // Se valida si esta habilitado CRUD
  const isCRUDAvailable = useCRUDAvailable();
  const crudAvailable = Lodash.get(
    isCRUDAvailable,
    ['data', 'response'],
    null,
  );
  const classes = useStyles();
  const tokenStorage = Storage.instance;
  const history = useHistory();

  const [user, setUser] = useState([]);
  const [items, setItems] = useState([]);
  const [isDataEmpty, setDataEmpty] = useState(false);

  const { decodedToken } = useJwt(tokenStorage.getToken());

  const [open, setOpenDialog] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false); // used on remove modal
  const [removedMsj, setRemoveMsj] = useState(
    'La Papeleta será anulada. ¿Desea proceder?',
  );
  const [dataRemove, setDataRemove] = React.useState(null);
  const [hiddenBtn, setHiddenBtn] = useState(false);
  // const [filteredItems, setFilteredItems] = useState([]); // used for filtering

  useEffect(() => {
    if (decodedToken) {
      setUser(decodedToken);
    }
  }, [decodedToken]);

  const printComprobante = codPapeleta => {
    getDataComprobante({
      codPapeleta,
      type: 4,
    }).then(r => {
      const comprobanteData = Lodash.get(
        r,
        ['data', 'response'],
        [],
      );

      // Informacion General
      const { generalInfo } = comprobanteData;

      // Informacion Gestor
      const gestorInfo = {
        cedula: user.num_cedula,
        nombre: user.des_nombre,
      };

      // Informacion Candidatos
      const candidatosInfo = {
        comite: comprobanteData.comite,
      };
      const montoInfo = {
        papeleta: comprobanteData.montosInfo.papeleta,
        membresia: comprobanteData.montosInfo.membresia,
        total: comprobanteData.montosInfo.total,
      };

      generateComprobantePresidenteRegimenMunicipal(
        'ASAMBLEAS PROVINCIALES',
        generalInfo,
        candidatosInfo,
        gestorInfo,
        montoInfo,
      );
    });
  };

  useEffect(() => {
    async function fetchListAPI() {
      if (decodedToken) {
        await presidenteRegimenMunicipalApi
          .getPresidentesRegimenMunicipal(
            decodedToken.num_cedula,
          )
          .then(_response => {
            const info = _response.data.response;

            if (info instanceof Object) {
              const rowItems = info.map(row => {
                return createData(row);
              });

              setItems(rowItems);
              // setFilteredItems(rowItems);
              setDataEmpty(false);
            } else {
              setDataEmpty(true);
            }
          })
          .catch(_error => {
            // eslint-disable-next-line no-console
            console.log(_error);
          });
      }
    }

    fetchListAPI();
  }, [decodedToken]);

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    if (crudAvailable) {
      const actionEdit = {
        aria: 'Editar Presidencia',
        key: 'edit',
        showAction: true,
        icon: (
          <EditIcon
            fontSize="inherit"
            style={{ color: '#84BF48' }}
          />
        ),
        clickHandler: _item => {
          window.location.href = `/presidente-regimen-municipal/edit/${_item.cod_papeleta}`;
        },
      };

      actions.push(actionEdit);

      const actionDecline = {
        aria: 'Anular Presidencia',
        key: 'remove',
        showAction: true,
        icon: (
          <BlockIcon
            fontSize="inherit"
            style={{ color: '#cc0000' }}
          />
        ),
        clickHandler: _item => {
          // eslint-disable-next-line no-console
          setDataRemove(_item);
          setOpenDialog(true);
        },
      };

      actions.push(actionDecline);
    }
    const actionPrintComprobante = {
      aria: 'Imprimir Comprobante',
      key: 'imprimir',
      showAction: true,
      icon: (
        <PrintIcon
          fontSize="inherit"
          style={{ color: '#666' }}
        />
      ),
      clickHandler: _item => {
        printComprobante(_item.cod_papeleta);
      },
    };

    actions.push(actionPrintComprobante);

    return actions;
  };

  /**
   * Anular papeleta
   */
  const declineSectorialesByNum = async () => {
    const currentCodPapeleta = dataRemove.cod_papeleta;

    if (currentCodPapeleta && currentCodPapeleta > 0) {
      setIsRemoved(true);

      await presidenteRegimenMunicipalApi
        .actionDeclinePapeleta({
          codPapeleta: currentCodPapeleta,
          cedulaGestor: decodedToken.num_cedula,
          consecutivo: dataRemove.consecutivo,
        })
        .then(_response => {
          const info = _response.data.response;

          if (info === 1 && _response.status === 200) {
            setIsRemoved(false);
            setHiddenBtn(true);
            setRemoveMsj(
              `Papeleta: ${currentCodPapeleta} anulada correctamente.`,
            );
            setTimeout(() => {
              setOpenDialog(false);
              window.location.href =
                '/presidente-regimen-municipal';
            }, 3000);
          } else {
            setHiddenBtn(false);
            setRemoveMsj(
              'La operación no se pudo completar. Intente de nuevo.',
            );
          }
        });
    }
  };

  return (
    <React.Fragment>
      <MacroList
        listName="Lista de Presidentes Régimen Municipal"
        dataEmpty={isDataEmpty}
        uiStylesClasses={classes}
        btnConfig={{
          name: '+ Registro',
          event: () =>
            history.push(
              '/presidente-regimen-municipal/add',
            ),
        }}
        actionConfig={setActions}
        tableHeads={tableHeaders}
        tableBody={items}
        isCrudAvailable={crudAvailable}
      />
      <PLNDialog
        open={open}
        onClose={() => {}}
        title="Anular Papeleta"
        bodymessage={removedMsj}
        buttons={
          hiddenBtn
            ? []
            : [
                {
                  actionName: 'cancel',
                  color: 'simple',
                  title: 'Cerrar',
                  clickHander: () => {
                    setOpenDialog(!open);
                  },
                },
                {
                  actionName: 'delete',
                  disabled: isRemoved,
                  color: 'remove',
                  title: isRemoved ? (
                    <CircularProgress
                      style={{
                        width: '28px',
                        height: '24px',
                      }}
                    />
                  ) : (
                    'Anular'
                  ),
                  clickHander: declineSectorialesByNum,
                },
              ]
        }
      />
    </React.Fragment>
  );
};

export default PresidenteRegimenMunicipal;
