/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Lodash from 'lodash';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { Formik, Field } from 'formik';
import { useJwt } from 'react-jwt';
import * as Yup from 'yup';
import Loading from '../../Components/Loading/Loading';
import { PlnDropdown } from '../../Components/Input';
import Copyright from '../Copyright/Copyright';
import ListSearch from '../../Components/ListSearch/ListSearch';
import Consts from '../../Core/Consts/Consts';
import style from './styles';
import { getEstadoPapeleta } from '../../Core/Utils/Utils';
import { generateComprobanteComiteEjecutivo } from '../../Core/Utils/Comprobante';
import { startPrevencion } from '../../Core/Utils/Prevencion';
import Storage from '../../Storage/Storage';

import { useCRUDAvailable } from '../../Core/Api/periodoApi';

import {
  usePapeletaInfo,
  updatePapeleta,
  getNumPapeletasDelegadoProvincial,
  getNumPapeletaStatus,
  blockNumPapeleta,
} from '../../Core/Api/papeletaComiteEjecutivoProvincial';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const EditComiteEjecutivoProvincial = props => {
  // Se valida si esta habilitado CRUD
  const isCRUDAvailable = useCRUDAvailable();
  const crudAvailable = Lodash.get(
    isCRUDAvailable,
    ['data', 'response'],
    null,
  );
  const { classes } = props;
  const { id } = useParams();
  const history = useHistory();

  const [user, setUser] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const [listDelegados, setListDelegados] = useState(
    Consts.initListDelegadosComiteEjecutivo,
  );

  const [listFiscales, setListFiscales] = useState(
    Consts.initListCandidatosFiscaliaEC,
  );

  const [msjDialog, setMsjDialog] = useState('');
  const [open, setOpenDialog] = React.useState(false);
  const [finalDialog, setFinalDialog] =
    React.useState(false);
  const handleClose = () => {
    setOpenDialog(false);
    setIsSaving(false);
  };

  const closeFinalDialog = () => {
    history.push('/comite-ejecutivo-provincial');
  };

  const validationSchema = Yup.object({});

  const tokenData = Storage.instance;
  const { decodedToken } = useJwt(tokenData.getToken());

  const numCedula = Lodash.get(
    decodedToken,
    ['num_cedula'],
    0,
  );

  const { data } = usePapeletaInfo({
    cedGestor: numCedula,
    codPapeleta: id,
  });

  // Datos Generales de la papeleta
  const papeletaData = Lodash.get(
    data,
    ['papeletaData'],
    [],
  );

  // Cantidad de Delegados
  const numDelegados = Lodash.get(
    data,
    ['numDelegados'],
    [],
  );

  // Lista de Candidatos incritos
  const candidatos = Lodash.get(data, ['candidatos'], []);
  useEffect(() => {
    if (decodedToken) {
      setUser(decodedToken);
    }
  }, [decodedToken]);

  if (!Lodash.isEmpty(papeletaData)) {
    const {
      numero,
      ind_estado,
      provincia,
      cod_prov,
      fec_inscripcion,
      num_consecutivo,
    } = papeletaData;

    // Date Options
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    const today = new Date(fec_inscripcion);
    const papeletaDate = today.toLocaleDateString(
      'es-ES',
      options,
    );

    const initialValues = Lodash.get(
      data,
      ['initValues'],
      [],
    );

    const setPapeleta = p => {
      initialValues.papeleta = p;
    };

    const papeletas = Lodash.get(data, ['papeletas'], []);

    // Se valida que exista al menos 1 en la lista
    const verifyIfCanSave = lCandidates => {
      const totalRegistersOnList = Object.keys(
        lCandidates,
      ).map(c => {
        const total = Lodash.countBy(
          lCandidates[c],
          rec => {
            return rec.filled === true;
          },
        );
        const result = Lodash.get(total, 'true', 0);
        return { count: result };
      });

      const canSave = Lodash.find(
        totalRegistersOnList,
        o => {
          return o.count > 0;
        },
      );

      let status = true;
      if (!canSave) {
        status = false;
      }

      return status;
    };

    const saveForm = (
      values,
      { setSubmitting, setFieldValue },
    ) => {
      const { papeleta } = values;

      // Se valida que se haya seleccionado un numero de papeleta
      if (papeleta === 999) {
        setMsjDialog(
          'Debe seleccionar el número de papeleta',
        );
        setOpenDialog(true);
        setIsSaving(false);
        setSubmitting(false);

        return;
      }

      // Se valida que esté el 50% de los Delegados
      const mitadDelegados = Math.floor(numDelegados / 2);
      const totalDelegados = Lodash.countBy(
        listDelegados,
        rec => {
          return rec.filled === true;
        },
      );

      if (
        totalDelegados.true === undefined ||
        totalDelegados.true < mitadDelegados
      ) {
        setMsjDialog(
          'Debe completarse el 50% de los delegados en la papeleta',
        );
        setOpenDialog(true);
        setSubmitting(false);
        return;
      }

      // Se valida que TODOS los campos de la Fiscalía
      const totalFiscalia = Lodash.countBy(
        listFiscales,
        rec => {
          return rec.filled === false;
        },
      );

      if (totalFiscalia.true !== undefined) {
        setMsjDialog(
          'Debe de completarse TODOS los candidatos para la Fiscalía',
        );
        setOpenDialog(true);
        setSubmitting(false);
        return;
      }

      // Segun el estado de la papeleta, se Crea o Actualiza
      getNumPapeletaStatus({
        papeleta,
        provincia: cod_prov,
      }).then(result => {
        const papeletaStatus = Lodash.get(
          result,
          ['data'],
          0,
        );

        if (papeletaStatus === 1) {
          // Encabezado Papeleta
          const delegadosProvincialEnca = {
            cod_prov,
            ind_estado: 2,
            num_papeleta: papeleta,
            num_consecutivo,
            num_cedula: user.num_cedula,
            des_usuario: user.des_login,
          };

          // Detalle Papeleta
          const tFrmsCandidatos = {
            delegados: listDelegados,
            fiscales: listFiscales,
          };

          // Se valida el 20% de la Cuota de Juventud en los DELEGADOS
          const porcentajeDelegados = Math.floor(
            tFrmsCandidatos.delegados.length * 0.2,
          );

          const totalCuotaJuventudD = Lodash.countBy(
            tFrmsCandidatos.delegados,
            rec => {
              return (
                Number.isInteger(rec.edad) && rec.edad <= 35
              );
            },
          );

          if (
            totalCuotaJuventudD.true === undefined ||
            totalCuotaJuventudD.true < porcentajeDelegados
          ) {
            setMsjDialog(
              // eslint-disable-next-line max-len
              'La papeleta NO cumple con el 20% de la cuota de Juventud en La lista de Delegados. Favor revise los miembros de su papeleta',
            );
            setOpenDialog(true);
            setSubmitting(false);
            return;
          }

          // Se Actualiza la Papeleta
          updatePapeleta({
            codPapeleta: id,
            delegadosProvincialEnca,
            tFrmsCandidatos,
          }).then(rUpdate => {
            const nCodPapeleta = Lodash.get(
              rUpdate,
              ['data', 'codPapeleta'],
              '',
            );

            const nMontos = Lodash.get(
              rUpdate,
              ['data', 'montos'],
              '',
            );

            if (nCodPapeleta !== '') {
              // Se bloquea el numero de papeleta
              blockNumPapeleta({
                numPapeleta: papeleta,
                codProvincia: cod_prov,
              });

              // COMPROBANTE: Se genera el Comprobante de la papeleta

              // Informacion General
              const generalInfo = {
                provincia,
                papeleta,
                fecha: papeletaDate,
                consecutivo: num_consecutivo,
              };

              // Informacion Gestor
              const gestorInfo = {
                cedula: user.num_cedula,
                nombre: user.des_nombre,
              };

              // Informacion Delegados
              const candidatosDelegados =
                tFrmsCandidatos.delegados.map(
                  ({ cedula, lugar, nombre }) => {
                    return [lugar, cedula, nombre];
                  },
                );

              const listCanDelegados = [];
              for (let cD = 0; cD < numDelegados; cD++) {
                listCanDelegados.push(
                  candidatosDelegados[cD],
                );
              }

              // Informacion Fiscales
              const candidatosFiscales =
                tFrmsCandidatos.fiscales.map(
                  ({ cedula, lugar, nombre }) => {
                    return [lugar, cedula, nombre];
                  },
                );

              // Informacion Candidatos
              const candidatosInfo = {
                delegados: listCanDelegados,
                fiscales: candidatosFiscales,
              };
              const montoInfo = {
                papeleta: nMontos.papeleta,
                membresia: nMontos.membresia,
                total: nMontos.total,
              };

              generateComprobanteComiteEjecutivo(
                'ASAMBLEAS PROVINCIALES',
                generalInfo,
                candidatosInfo,
                gestorInfo,
                montoInfo,
              );

              // PROCESO DE PREVENCIÓN
              startPrevencion(
                user,
                nCodPapeleta,
                num_consecutivo,
                2,
                'Papeleta Comité Ejecutivo Provincial',
                papeletaDate,
                gestorInfo,
              );

              setSubmitting(false);
              setMsjDialog(
                'La Información de la papeleta se ACTUALIZÓ correctamente.',
              );
              setFinalDialog(true);
            } else {
              setMsjDialog(
                'La información NO pudo ser actualizada',
              );
              setSubmitting(false);
              setOpenDialog(true);
            }
          });
        } else {
          setMsjDialog(
            // eslint-disable-next-line max-len
            'ATENCIÓN: El número de papeleta seleccionado YA fue elegido. Debe de seleccionar otro número de papeleta',
          );
          setOpenDialog(true);
          setIsSaving(false);
          setSubmitting(false);
          setFieldValue('papeleta', 999);
          getNumPapeletasDelegadoProvincial(cod_prov).then(
            r => {
              if (r.status === 200) {
                const papeletasList = Lodash.get(r, [
                  'data',
                ]);
                const plist = papeletasList.map(p => {
                  return {
                    itemLabel: p.papeleta,
                    value: p.papeleta,
                  };
                });
                plist.unshift({
                  itemLabel: '- NUMERO DE PAPELETA -',
                  value: 999,
                });

                // setPapeletas(plist);
              }
            },
          );
        }
      });
    };

    const savePapeleta = values => () => {
      setIsSaving(true);
      const { papeleta } = values;

      // Se valida que se haya seleccionado un numero de papeleta
      if (papeleta === 999) {
        setMsjDialog(
          'Debe seleccionar el número de papeleta',
        );
        setOpenDialog(true);
        setIsSaving(false);
        return;
      }

      const tFrmsCandidatos = {
        delegados: listDelegados,
        fiscales: listFiscales,
      };

      // Se valida que exista al menos 1 candidato en la papeleta
      const canSave = verifyIfCanSave(tFrmsCandidatos);

      if (canSave) {
        const delegadosProvincialEnca = {
          cod_prov,
          ind_estado: 3,
          num_papeleta: papeleta,
          num_consecutivo,
          num_cedula: user.num_cedula,
          des_usuario: user.des_login,
        };

        updatePapeleta({
          codPapeleta: id,
          delegadosProvincialEnca,
          tFrmsCandidatos,
        }).then(result => {
          const nCodPapeleta = Lodash.get(
            result,
            ['data', 'codPapeleta'],
            '',
          );
          if (nCodPapeleta !== '') {
            setMsjDialog(
              'La Información de la papeleta se ACTUALIZÓ correctamente.',
            );
            setOpenDialog(true);
          } else {
            setMsjDialog(
              'La información NO pudo ser actualizada',
            );
            setOpenDialog(true);
            setIsSaving(false);
          }
        });
      } else {
        setMsjDialog(
          'Al menos debe de haber ingresado un candidato en la papeleta',
        );
        setOpenDialog(true);
        setIsSaving(false);
      }
    };

    return (
      <>
        {crudAvailable ? (
          <Container
            maxWidth="lg"
            className={classes.container}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography
                    component="h2"
                    variant="h6"
                    color="primary"
                    gutterBottom
                  >
                    {`Editar Papeleta Distrital: ${id}`}
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.btnBack}
                      onClick={() => {
                        history.push(
                          '/comite-ejecutivo-provincial',
                        );
                      }}
                    >
                      <ArrowBackIcon /> Regresar
                    </Button>
                  </Typography>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={saveForm}
                  >
                    {formik => (
                      <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={3}>
                            <h4
                              className={classes.fieldTitle}
                            >
                              Número de Papeleta:
                            </h4>
                            {ind_estado === 3 ? (
                              <Field
                                name="papeleta"
                                data={papeletas}
                                component={PlnDropdown}
                                onChange={papeleta =>
                                  setPapeleta(papeleta)
                                }
                              />
                            ) : (
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {numero}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        <Divider />
                        <div>
                          <Typography
                            component="h2"
                            variant="h6"
                            color="primary"
                            gutterBottom
                            className={classes.sectionTitle}
                          >
                            Datos del Gestor
                          </Typography>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Estado
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {getEstadoPapeleta(
                                  ind_estado,
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Fecha
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {papeletaDate}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Consecutivo
                              </h4>
                              <div
                                className={
                                  num_consecutivo === ''
                                    ? classes.field
                                    : classes.fieldFilled
                                }
                              >
                                {num_consecutivo}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Cédula
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {user.num_cedula}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Nombre
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {user.des_nombre}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Provincia
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {provincia}
                              </div>
                            </Grid>
                          </Grid>

                          <ListSearch
                            title="Candidatos(as) Delegados(as) Provinciales"
                            formik={formik}
                            initListData={
                              candidatos.listDelegados
                            }
                            sexOrderM={
                              Consts.delegadosMOrder
                            }
                            sexOrderF={
                              Consts.delegadosFOrder
                            }
                            fieldToShow={numDelegados}
                            // eslint-disable-next-line radix
                            inPlace={parseInt(cod_prov)}
                            setListFunc={setListDelegados}
                            validateBy={[
                              {
                                dataVal: 'provincia',
                                value: cod_prov,
                              },
                            ]}
                          />

                          <ListSearch
                            title="Candidato(a) a la Fiscalía"
                            formik={formik}
                            initListData={
                              candidatos.listFiscales
                            }
                            sexOrderM={[{ sexo: 'M' }]}
                            sexOrderF={[{ sexo: 'F' }]}
                            fieldToShow={0}
                            // eslint-disable-next-line radix
                            inPlace={parseInt(cod_prov)}
                            setListFunc={setListFiscales}
                            validateBy={[
                              {
                                dataVal: 'provincia',
                                value: cod_prov,
                              },
                            ]}
                            findInOtherList
                            otherList={[listDelegados]}
                            isFiscal
                          />

                          <Grid
                            container
                            spacing={3}
                            style={{ marginTop: 40 }}
                          >
                            <Grid item xs={12}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                className={
                                  formik.isSubmitting ||
                                  isSaving
                                    ? classes.btnSubmitting
                                    : classes.btnActions
                                }
                                disabled={
                                  formik.isSubmitting ||
                                  isSaving
                                }
                              >
                                {formik.isSubmitting ? (
                                  <CircularProgress
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                    }}
                                  />
                                ) : (
                                  <>
                                    Enviar
                                    <SendIcon
                                      className={
                                        classes.frmIconButton
                                      }
                                    />
                                  </>
                                )}
                              </Button>

                              <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                size="small"
                                className={
                                  formik.isSubmitting ||
                                  isSaving
                                    ? classes.btnSubmitting
                                    : classes.btnActions
                                }
                                disabled={
                                  formik.isSubmitting ||
                                  isSaving
                                }
                                onClick={savePapeleta(
                                  formik.values,
                                )}
                              >
                                {isSaving ? (
                                  <CircularProgress
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                    }}
                                  />
                                ) : (
                                  <>
                                    Actualizar{' '}
                                    <SaveIcon
                                      style={{
                                        marginLeft: 10,
                                      }}
                                    />
                                  </>
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle
                      id="alert-confirm-messages"
                      color="warning"
                    >
                      Mensaje
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        {msjDialog}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        color="primary"
                      >
                        Entendido
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={finalDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={closeFinalDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle
                      id="alert-finalDialog"
                      color="warning"
                    >
                      Mensaje
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        {msjDialog}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={closeFinalDialog}
                        color="primary"
                      >
                        Cerrar
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Paper>
              </Grid>
            </Grid>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        ) : (
          <Container
            maxWidth="lg"
            className={classes.container}
            style={{
              textAlign: 'center',
            }}
          >
            <Grid
              container
              spacing={3}
              style={{ marginTop: 20 }}
            >
              <Grid
                item
                xs={12}
                style={{
                  textAlign: 'center',
                  backgroundColor: '#FFE599',
                  color: '#FF9326',
                }}
              >
                <h4>
                  Esta sección se encuentra temporalmente
                  deshabilitada. <br />
                  Favor comunicarse con Ezequiel Hernández
                  al 2519-5521 o al correo
                  ehernandez@plndigital.com
                </h4>
              </Grid>
            </Grid>
          </Container>
        )}
      </>
    );
    // eslint-disable-next-line no-else-return
  } else {
    return (
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Loading label="Cargando" />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }
};

export default withStyles(style)(
  EditComiteEjecutivoProvincial,
);
